import React from 'react';

import './App.scss';
import AppRouter from './routes/AppRouter';

const App = () => {
  return <AppRouter />;
};

export default App;
