import { Button, Card, Modal } from 'react-bootstrap';
import 'src/components/FormModal/form-modal.scss';

const FormModal = ({
  show = false,
  title = {},
  centered = false,
  body,
  onHide = () => {},
  onReset = () => {},
  onSubmit = () => {},
  submitted = false,
  isRightSticky = false
}) => {
  const handleSubmit = () => {
    onSubmit();
  };
  return (
    <>
      <Modal
        show={show}
        centered={centered}
        onHide={onHide}
        aria-labelledby={centered && 'contained-modal-title-vcenter'}
        dialogClassName={isRightSticky ? 'modal-rt-sticky' : ''}
      >
        <Card style={{ marginBottom: 0 }}>
          <Card.Header>
            <button type="button" className="close" onClick={onHide}>
              <span aria-hidden="true">&times;</span>
            </button>
            <Card.Title as="h5">{title}</Card.Title>
          </Card.Header>
          <Card.Body>{body}</Card.Body>
        </Card>
        <Modal.Footer>
          <Button onClick={onReset}>Reset</Button>
          <Button disabled={submitted} onClick={handleSubmit}>Submit</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FormModal;
