import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import { Col, Form, FormCheck } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { cloneDeep } from 'lodash';
import useAuth from 'src/services/api';

const schema = Yup.object().shape({
  name: Yup.string().required(),
  approval_required: Yup.string().required('Approval selection is required'),
  frequency: Yup.string().required(),
  start_date: Yup.string().required(),
  quantity: Yup.string().required(),
  leave_type: Yup.string().required()
});

const AddLeavePolicy = forwardRef((props, ref) => {
  const formikRef = useRef();
  const { addLeavePolicy } = useAuth();
  const [submitted, setSubmitted] = useState(false);
  useImperativeHandle(ref, () => ({
    resetForm() {
      formikRef.current.resetForm();
    },
    submitForm() {
      formikRef.current.submitForm();
    }
  }));

  const onFormSubmit = async (values) => {
    setSubmitted(true);
    const subVal = cloneDeep(values);
    const date = new Date(subVal.start_date);

    const formattedDate = `${String(date.getDate()).padStart(2, '0')}-${String(date.getMonth() + 1).padStart(
      2,
      '0'
    )}-${date.getFullYear()}`;

    subVal.start_date = formattedDate;
    subVal.approval_required = values.approval_required === 'yes';
    console.log(subVal);
    await addLeavePolicy(subVal);
    window.location.reload();
  };

  return (
    <Formik
      innerRef={formikRef}
      validationSchema={schema}
      onSubmit={onFormSubmit}
      initialValues={{
        name: '',
        approval_required: 'yes',
        frequency: 'monthly',
        start_date: '',
        quantity: '',
        leave_type: 'casual',
        apply_to_all_employees: false
      }}
    >
      {({ handleSubmit, handleChange, setFieldValue, handleBlur, values, touched, isValid, errors }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Form.Row>
            <Form.Group as={Col} md="12" controlId="validationFormik101">
              <Form.Label>Policy Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={submitted ? !!errors.name : touched.name && !!errors.name}
              />
              {touched.name && errors.name && (submitted || touched.name) && (
                <Form.Control.Feedback type="invalid" tooltip>
                  {errors.name}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group as={Col} md="12" controlId="validationFormik102">
              <Form.Label>Approval Required</Form.Label>
              <Form.Control
                as="select"
                name="approval_required"
                value={values.approval_required}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={submitted ? !!errors.approval_required : touched.approval_required && !!errors.approval_required}
              >
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </Form.Control>

              {touched.approval_required && errors.approval_required && (submitted || touched.approval_required) && (
                <Form.Control.Feedback type="invalid" tooltip>
                  {errors.approval_required}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group as={Col} md="12" controlId="validationFormik103">
              <Form.Label>Frequency</Form.Label>
              <Form.Control
                as="select"
                name="frequency"
                value={values.frequency}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={submitted ? !!errors.frequency : touched.frequency && !!errors.frequency}
              >
                <option value="montly">Monthly</option>
                <option value="quarterly">Quaterly</option>
                <option value="semi_annual">Semi Anually</option>
                <option value="annual">Anually</option>
              </Form.Control>

              {touched.frequency && errors.frequency && (submitted || touched.frequency) && (
                <Form.Control.Feedback type="invalid" tooltip>
                  {errors.frequency}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group as={Col} md="12" controlId="validationFormik106">
              <Form.Label>Start Date</Form.Label>
              {/* <Form.Control
                type="text"
                name="start_date"
                value={values.start_date}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={submitted ? !!errors.start_date : touched.start_date && !!errors.start_date}
              /> */}
              <br />
              <DatePicker
                name="start_date"
                selected={values.start_date}
                onChange={(date) => setFieldValue('start_date', date)}
                autoComplete="off"
                shouldCloseOnSelect={true}
                closeOnSelect={true}
                className={`form-control ${touched.start_date && errors.start_date ? 'is-invalid' : ''}`}
              />

              {touched.start_date && errors.start_date && (submitted || touched.start_date) && (
                <Form.Control.Feedback type="invalid" tooltip>
                  {errors.start_date}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group as={Col} md="12" controlId="validationFormik104">
              <Form.Label>Quantity</Form.Label>
              <Form.Control
                type="text"
                name="quantity"
                value={values.quantity}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={submitted ? !!errors.quantity : touched.quantity && !!errors.quantity}
              />

              {touched.quantity && errors.quantity && (submitted || touched.quantity) && (
                <Form.Control.Feedback type="invalid" tooltip>
                  {errors.quantity}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group as={Col} md="12" controlId="validationFormik105">
              <Form.Label>Leave Type</Form.Label>
              <Form.Control
                as="select"
                name="leave_type"
                value={values.leave_type}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={submitted ? !!errors.leave_type : touched.leave_type && !!errors.leave_type}
              >
                <option value="casual">Casual</option>
                <option value="sick">Sick</option>
                <option value="wfh">Work From Home</option>
                <option value="misc">Miscellaneous</option>
              </Form.Control>

              {touched.leave_type && errors.leave_type && (submitted || touched.leave_type) && (
                <Form.Control.Feedback type="invalid" tooltip>
                  {errors.leave_type}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group as={Col} md="12" controlId="validationFormik105">
              <FormCheck>
                <Field id={'apply_to_all_employees'} name={'apply_to_all_employees'} type="checkbox" className="form-check-input" />
                <FormCheck.Label htmlFor={'apply_to_all_employees'} className="form-check-label">
                  {'Apply to all Employees'}
                </FormCheck.Label>
              </FormCheck>
            </Form.Group>
          </Form.Row>
          {/* <Card.Footer style={{ display: 'flex', gap: 8, justifyContent: 'flex-end', padding:0, paddingTop: 8 }}>
              <Button variant="outline-primary" style={{ marginRight: 0 }}>
                Reset
              </Button>
              <Button type="submit" style={{ marginRight: 0 }}>
                Submit
              </Button>
          </Card.Footer> */}
        </Form>
      )}
    </Formik>
  );
});

export default AddLeavePolicy;
