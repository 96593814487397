import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useAuth from 'src/services/api';
import Avatar from 'src/components/Avatar';
import { Breadcrumb, Button, Card, Col, Row } from 'react-bootstrap';
import 'src/views/Employees/employee-profile.scss';
import usePermission from 'src/hooks/usePermission';
import Loader from 'src/components/Loadify/Loader';
import { calculateAge, generateEmployeeCode } from 'src/utils/common';

const EmployeeProfile = () => {
  const params = useParams();
  const [employee, setEmployee] = useState({});
  const { fetchEmployeeDetail } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const { isSuperAdmin, isCurrentUser } = usePermission();
  const {fetchActiveDesignation } = useAuth();
  const [designations, setDesignations] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { empID } = params;
        const { data } = await fetchEmployeeDetail(empID);
        if (data?.employee) {
          const { employee } = data;
          data.employee.name = `${employee.first_name} ${employee.last_name}`;
          setEmployee(data?.employee);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        // Handle errors here
        console.error('Error fetching data:', error);
      }
    };

    // Call fetchData function when component mounts (on page load)
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchActiveDesignation();

        if (response.data && response.data.designations && response.data.designations.length > 0) {
          setDesignations(response.data.designations);
        } else {
          console.error('No designations data found or data format is incorrect');
        }
      } catch (error) {
        // Handle errors here
        console.error('Error fetching data:', error);
      }
    };

    // Call fetchData function when component mounts (on page load)
    fetchData();
  }, []);

  const getDesignationTitle = (designationId) => {
    const designation = designations.find(d => d.id === designationId);
    return designation ? designation.title : 'Designation Not Assign';
  };

  const isDataViewable = () => {
    if (isSuperAdmin() || isCurrentUser(employee.id)) return true;
    return false;
  };

  if (isLoading) return <Loader />;

  return (
    <Row>
      <Col md={12}>
        <Breadcrumb>
          <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item href="/employees">Employee</Breadcrumb.Item>
          <Breadcrumb.Item active>{employee.name}</Breadcrumb.Item>
        </Breadcrumb>
      </Col>

      <Col md={12}>
        <div className='edit-btn'>
          {isCurrentUser(employee.id) && (
            <Button href={`/employee/edit/${employee.id}`} variant="outline-primary" style={{ marginRight: 0 }}>
              <i className="fas fa-edit" />
              Edit Profile
            </Button>
          )}
        </div>
      </Col>
      <Col md={12}>
        <Row>
          <Col md={4}>
            {/* Employee Profile Image */}
            <Card>
              <Card.Body>
                <div className="avatar-wrapper">
                  <Avatar email={employee.email} alt={employee.name} url={employee.avatar} />
                </div>
                <div className="basic-info">
                  <h3>{employee.name}</h3>
                  <p>Account Status: {employee.active ? 'Active' : 'Inactive'}</p>
                  <p>Designation: {getDesignationTitle(employee.employee_designations?.designation_id)}</p>
                  <p>Email: {employee.email}</p>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col md={8}>
            <Card>
              <Card.Body>
                <Card.Subtitle>Details</Card.Subtitle>
                <hr />
                <Card.Text>
                  <strong>Employee Code:</strong> {employee.code}
                </Card.Text>
                <Card.Text>
                  <strong>Reports to: {employee.reports_to.map((r) => r.name).join(', ')}</strong>
                </Card.Text>
                <Card.Text>
                  <strong>DOJ:</strong> {employee.date_of_joining}
                </Card.Text>
                {/* <Card.Text>
              <strong>Direct Reports:</strong>
            </Card.Text> */}
                <br />
                <Card.Subtitle>Personal Details</Card.Subtitle>
                <hr />
                <Card.Text>
                  <strong>Date of birth:</strong> {employee.date_of_birth}
                </Card.Text>
                <Card.Text>
                  <strong>Age:</strong> {employee.age}
                </Card.Text>
                <Card.Text>
                  <strong>Nationality:</strong> {employee.nationality}
                </Card.Text>
                <Card.Text>
                  <strong>Gender:</strong> {employee.date_of_joining}
                </Card.Text>
                <Card.Text>
                  <strong>Phone No.:</strong> {employee.phone_number}
                </Card.Text>
                <br />
                <Card.Subtitle>Address</Card.Subtitle>
                <hr />
                <Card.Text>
                  <strong>Street:</strong> {employee.address?.street}
                </Card.Text>
                <Card.Text>
                  <strong>City:</strong> {employee.address?.city}
                </Card.Text>
                <Card.Text>
                  <strong>State:</strong> {employee.address?.state}
                </Card.Text>
                <Card.Text>
                  <strong>Zip Code:</strong> {employee.address?.zipcode}
                </Card.Text>
                <br />
                <Card.Subtitle>Personal contact details</Card.Subtitle>
                <hr />
                <Card.Text>
                  <strong>Personal Email:</strong> {employee.personal_email}
                </Card.Text>
                <Card.Text>
                  <strong>Personal phone no. :</strong> {employee.personal_number}
                </Card.Text>
                {isDataViewable() && (
                  <>
                    <br />
                    <Card.Subtitle>Identification</Card.Subtitle>
                    <hr />
                    <Card.Text>
                      <strong>Aadhar Number:</strong> {employee.aadhar_number}
                    </Card.Text>

                    <Card.Text>
                      <strong>Pan Number:</strong> {employee.pan_number}
                    </Card.Text>

                    <Card.Text>
                      <strong>Passport Number:</strong> {employee.passport_number}
                    </Card.Text>
                  </>
                )}

                {isDataViewable() && (
                  <>
                    <br />
                    <Card.Subtitle>Payroll</Card.Subtitle>
                    <hr />

                    {/* <Card.Text>
                      <strong>Variable Pay:</strong> {employee.variablePay}
                    </Card.Text>
                    <Card.Text>
                      <strong>Fixed Pay:</strong> {employee.fixedPay}
                    </Card.Text> */}
                    <Card.Text>
                      <strong>Total Pay:</strong> {employee.salary}
                    </Card.Text>
                    {/* <Card.Text>
                      <strong>Salary pay frequency:</strong> {employee.salary}
                    </Card.Text> */}
                  </>
                )}
                <br></br>
                <Card.Subtitle>Emergecy Contact Details</Card.Subtitle>
                <hr />
                <Card.Text>
                  <strong>Contact Name:</strong> {employee.emergency_contact_name}
                </Card.Text>
                <Card.Text>
                  <strong>Contact Relation:</strong> {employee.emergency_contact_relation}
                </Card.Text>
                <Card.Text>
                  <strong>Contact No. :</strong> {employee.emergency_contact_number}
                </Card.Text>
                {/* Add more details as needed */}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default EmployeeProfile;
