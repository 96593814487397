import React, { useEffect, useRef, useState } from 'react';
import { Row, Col, Card, Table, Button } from 'react-bootstrap';
import useAuth from 'src/services/api';
import { NoData } from 'src/components/NoData/NoData';
import Loader from 'src/components/Loadify/Loader';

const Payslips = () => {
  const { fetchPayslips } = useAuth();
  const [payslips, setPayslips] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { downloadPayslips } = useAuth();

  useEffect(() => {
    const fetchPayslipData = async () => {
      try {
        const { data } = await fetchPayslips();

        setPayslips(data?.payslips || []);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoading(false);
      }
    };

    fetchPayslipData();
  }, []);

  const handleDownload = async (id) => {
    try {
      const response = await downloadPayslips(id);
      console.log("Response:", response);
  
      if (response.data) {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'payslip.pdf');
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } else {
        console.error("Unexpected response data:", response.data);
      }
    } catch (error) {
      console.error('Error downloading payslip:', error);
    }
};


  if (isLoading) return <Loader />;

  return (
    payslips.length === 0 ? 
      <NoData text='No payslip' /> :
      <Card>
        <Card.Header>
          <Card.Title as="h5">Payslips</Card.Title>
        </Card.Header>
        <Card.Body>
          <Table responsive hover>
            <thead>
              <tr>
                <th>Generated At</th>
                <th>Payslip</th>
              </tr>
            </thead>
            <tbody>
              {payslips.map((payslip, index) => {
                const date = new Date(payslip.generated_at);
                const day = String(date.getUTCDate()).padStart(2, '0');
                const month = String(date.getUTCMonth() + 1).padStart(2, '0');
                const year = date.getUTCFullYear();
                const formattedDate = `${day}/${month}/${year}`;
                return (
                  <tr key={index}>
                    <td>{formattedDate}</td>
                    <td>
                      <Button className='p-0' variant="link" onClick={() => handleDownload(payslip.id)}>
                        Download
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
  );
};

export default Payslips;
