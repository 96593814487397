import React, { useState } from 'react';
import { Form, Col, Row, Image, Button, Card } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import './add-employee.scss'; // Import a custom CSS file for additional styling
import ActionIcons from 'src/components/ActionIcons/ActionIcons';
import useAuth from 'src/services/api';
import { useNavigate } from 'react-router-dom';
import { capitalize } from 'lodash';
import { useToasts } from 'react-toast-notifications';
import { useEffect } from 'react';

const schema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required'),
  password: Yup.string().required('Password is required'),
  designation_id: Yup.string().required('Designation is required'),
  phone_number: Yup.string().required('Phone Number is required')
  .matches(/^[0-9]{10}$/, 'Phone Number must be a 10-digit number'),
  emergency_contact_number: Yup.string().matches(/^[0-9]{10}$/, 'Phone Number must be a 10-digit number'),
  first_name: Yup.string().required('First Name is required'),
  last_name: Yup.string().required('Last Name is required'),
  date_of_joining: Yup.string().required('Dte of joining is required'),
  date_of_birth: Yup.string().required('Date of birth is required'),
  nationality: Yup.string().required('Nationality is required'),
});

const EmployeeForm = () => {
  const [submitted, setSubmitted] = useState(false);
  const {fetchActiveDesignation } = useAuth();
  const [designations, setDesignations] = useState([]);
  const { addEmployee } = useAuth();
  const { addToast } = useToasts();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchActiveDesignation();

        if (response.data && response.data.designations && response.data.designations.length > 0) {
          setDesignations(response.data.designations);
        } else {
          console.error('No designations data found or data format is incorrect');
        }
      } catch (error) {
        // Handle errors here
        console.error('Error fetching data:', error);
      }
    };

    // Call fetchData function when component mounts (on page load)
    fetchData();
  }, []);
  
  const handleAvatarClick = (e, values) => {
    e.preventDefault();
    !values.avatar && document.getElementById('avatarInput').click();
  };

  const handleRemoveImage = (e, setFieldValue) => {
    e.preventDefault();
    setFieldValue('avatar', '');
  };

  const onFormSubmit = async (values) => {
    setSubmitted(true);
    const { data } = await addEmployee(values);
    if (data.success || data.employee) {
      addToast(`Employee Created Successfully!`, {
        appearance: 'success',
        autoDismiss: true
      });
      setTimeout(() => {
        navigate(`/employee/${data.employee?.id}`);
      }, 1500);
    } else {
      setSubmitted(false);
      const errorKeys = Object.keys(data.errors);
      addToast(`${capitalize(errorKeys[0])} ${data.errors[errorKeys[0]]}`, {
        appearance: 'error',
        autoDismiss: true
      });
    }
  };

  const handleAvatarSelect = (event, setFieldValue) => {
    if (event.target.files.length > 0) {
      setFieldValue('avatar', URL.createObjectURL(event.target.files[0]));
    }
  };

  return (
    <div className="col-12">
      <Formik
        validationSchema={schema}
        onSubmit={onFormSubmit}
        initialValues={{
          avatar: '',
          code: '',
          email: '',
          first_name: '',
          last_name: '',
          password: '',
          designation_id: '',
          active: true,
          date_of_joining: '',
          date_of_birth: '',
          nationality: '',
          gender: 'male',
          phone_number: '',
          personal_email: '',
          personal_number: '',
          aadhar_number: '',
          pan_number: '',
          passport_number: '',
          variable_pay: '',
          fixed_pay: '',
          total_pay: '',
          salary: '',
          street: '',
          city: '',
          state: '',
          zipcode: '',
          emergency_contact_name: '',
          emergency_contact_relation: '',
          emergency_contact_number: ''
        }}
      >
        {({ handleSubmit, handleChange, handleBlur, setFieldValue, values, touched, isValid, errors }) => (
          <Form noValidate onSubmit={handleSubmit} className="employee-form">
            <Row>
              {/* className="flex-column" */}
              <Form.Group as={Col} controlId="avatar">
                <div className="avatar-container">
                  <div className="avatar-wrapper" onClick={(e) => handleAvatarClick(e, values)}>
                    {values.avatar && (
                      <div>
                        <Image src={values.avatar} alt="Avatar" roundedCircle className="avatar-image" />
                        <div className="remove-image" onClick={(e) => handleRemoveImage(e, setFieldValue)}>
                          <ActionIcons type="cancel" />
                        </div>
                      </div>
                    )}
                    {!values.avatar && <div className="avatar-placeholder">Upload Profile Picture</div>}
                  </div>
                </div>
                {touched.avatar && errors.avatar && (submitted || touched.avatar) && (
                  <Form.Control.Feedback type="invalid" tooltip>
                    {errors.avatar}
                  </Form.Control.Feedback>
                )}
                {!values.avatar && (
                  <input
                    id="avatarInput"
                    type="file"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={(e) => handleAvatarSelect(e, setFieldValue)}
                  />
                )}
              </Form.Group>

              <Form.Group as={Col} md={4} controlId="code">
                <Form.Label>Employee Code</Form.Label>
                <Form.Control
                  type="text"
                  name="code"
                  value={values.code}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="input-field"
                />
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="email">
                <Form.Label>Email*</Form.Label>
                <div className="form-wrapper">
                  <Form.Control
                    type="email"
                    name="email"
                    autoComplete="username"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={submitted ? !!errors.email : touched.email && !!errors.email}
                  />

                  {touched.email && errors.email && (submitted || touched.email) && (
                    <Form.Control.Feedback type="invalid" tooltip>
                      {errors.email}
                    </Form.Control.Feedback>
                  )}
                </div>
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="first_name">
                <Form.Label>First Name*</Form.Label>
                <div className="form-wrapper">
                  <Form.Control
                    type="first_name"
                    name="first_name"
                    value={values.first_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={submitted ? !!errors.first_name : touched.first_name && !!errors.first_name}
                  />
                  {touched.first_name && errors.first_name && (submitted || touched.first_name) && (
                    <Form.Control.Feedback type="invalid" tooltip>
                      {errors.first_name}
                    </Form.Control.Feedback>
                  )}
                </div>
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="last_name">
                <Form.Label>Last Name*</Form.Label>
                <div className="form-wrapper">
                  <Form.Control
                    type="last_name"
                    name="last_name"
                    value={values.last_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={submitted ? !!errors.last_name : touched.last_name && !!errors.last_name}
                  />
                  {touched.last_name && errors.last_name && (submitted || touched.last_name) && (
                    <Form.Control.Feedback type="invalid" tooltip>
                      {errors.last_name}
                    </Form.Control.Feedback>
                  )}
                </div>
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="password">
                <Form.Label>Password*</Form.Label>
                <div className="form-wrapper">
                  <Form.Control
                    type="password"
                    name="password"
                    autoComplete="current-password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={submitted ? !!errors.password : touched.password && !!errors.password}
                  />

                  {touched.password && errors.password && (submitted || touched.password) && (
                    <Form.Control.Feedback type="invalid" tooltip>
                      {errors.password}
                    </Form.Control.Feedback>
                  )}
                </div>
              </Form.Group>

              {/* Add other form groups for Employee Code, Reports To, etc. */}

              <Form.Group as={Col} md={4} controlId="date_of_joining">
                <Form.Label>Date of Joining*</Form.Label>
                <div className="custom-date-picker">
                  <Datetime
                    inputProps={{ name: 'date_of_joining', readOnly: true }}
                    value={values.date_of_joining}
                    onChange={(date) => setFieldValue('date_of_joining', date)}
                    timeFormat={false}
                    closeOnSelect={true}
                    className={`date-time-picker ${touched.date_of_joining && errors.date_of_joining ? 'is-invalid' : ''}`}
                  />
                  {touched.date_of_joining && errors.date_of_joining && (submitted || touched.date_of_joining) && (
                    <Form.Control.Feedback type="invalid" tooltip>
                      {errors.date_of_joining}
                    </Form.Control.Feedback>
                  )}
                  <span className="calendar-icon">
                    <i className="fa fa-calendar" aria-hidden="true"></i>
                  </span>
                </div>
              </Form.Group>

              <Form.Group as={Col} md={4} controlId="designation">
                <Form.Label>Designation*</Form.Label>
                <div className="form-wrapper">
                  <Form.Control
                    as="select"
                    name="designation_id"
                    value={values.designation_id}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="input-field"
                    isInvalid={submitted ? !!errors.designation_id : touched.designation_id && !!errors.designation_id}
                  >
                    <option value="">Select a designation</option>
                    {designations.map((designation) => (
                      <option key={designation.id} value={designation.id}>
                        {designation.title}
                      </option>
                    ))}
                  </Form.Control>
                  {touched.designation_id && errors.designation_id && (submitted || touched.designation_id) && (
                    <Form.Control.Feedback type="invalid" tooltip>
                      {errors.designation}
                    </Form.Control.Feedback>
                  )}
                </div>
              </Form.Group>

              <Form.Group as={Col} md={4} controlId="date_of_birth">
                <Form.Label>Date of Birth*</Form.Label>
                <div className="custom-date-picker">
                  <Datetime
                    inputProps={{ name: 'date_of_birth', readOnly: true }}
                    value={values.date_of_birth}
                    onChange={(date) => setFieldValue('date_of_birth', date)}
                    timeFormat={false}
                    closeOnSelect={true}
                    className={`date-time-picker ${touched.date_of_birth && errors.date_of_birth ? 'is-invalid' : ''}`}
                  />
                  {touched.date_of_birth && errors.date_of_birth && (submitted || touched.date_of_birth) && (
                    <Form.Control.Feedback type="invalid" tooltip>
                      {errors.date_of_birth}
                    </Form.Control.Feedback>
                  )}
                  <span className="calendar-icon">
                    <i className="fa fa-calendar" aria-hidden="true"></i>
                  </span>
                </div>
              </Form.Group>
              <Form.Group as={Col} md={4} controlId="nationality">
                <Form.Label>Nationality*</Form.Label>

                <div className="form-wrapper">
                  <Form.Control
                    type="text"
                    name="nationality"
                    value={values.nationality}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="input-field"
                    isInvalid={submitted ? !!errors.nationality : touched.nationality && !!errors.nationality}
                  />
                  {touched.nationality && errors.nationality && (submitted || touched.nationality) && (
                    <Form.Control.Feedback type="invalid" tooltip>
                      {errors.nationality}
                    </Form.Control.Feedback>
                  )}
                </div>
              </Form.Group>

              <Form.Group as={Col} md={4} controlId="gender">
                <Form.Label>Gender*</Form.Label>
                <Form.Control
                  as="select"
                  name="gender"
                  value={values.gender}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="input-field"
                >
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </Form.Control>
              </Form.Group>

              <Form.Group as={Col} md={4} controlId="phone_number">
                <Form.Label>Phone Number*</Form.Label>
                <div className="form-wrapper">
                  <Form.Control
                    type="text"
                    name="phone_number"
                    value={values.phone_number}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="input-field"
                    isInvalid={submitted ? !!errors.phone_number : touched.phone_number && !!errors.phone_number}
                  />
                  {touched.phone_number && errors.phone_number && (submitted || touched.phone_number) && (
                    <Form.Control.Feedback type="invalid" tooltip>
                      {errors.phone_number}
                    </Form.Control.Feedback>
                  )}
                </div>
              </Form.Group>

              <Form.Group as={Col} md={4} controlId="personal_email">
                <Form.Label>Personal Email</Form.Label>
                <Form.Control
                  type="text"
                  name="personal_email"
                  value={values.personal_email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="input-field"
                />
                {/* {touched.personal_email && errors.personal_email && (submitted || touched.personal_email) && (
                  <Form.Control.Feedback type="invalid" tooltip>
                    {errors.personal_email}
                  </Form.Control.Feedback>
                )} */}
              </Form.Group>

              <Form.Group as={Col} md={4} controlId="personal_number">
                <Form.Label>Personal Number</Form.Label>
                <Form.Control
                  type="text"
                  name="personal_number"
                  value={values.personal_number}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="input-field"
                />
                {/* {touched.personal_number && errors.personal_number && (submitted || touched.personal_number) && (
                  <Form.Control.Feedback type="invalid" tooltip>
                    {errors.personal_number}
                  </Form.Control.Feedback>
                )} */}
              </Form.Group>


              <Form.Group as={Col} md={4} controlId="street">
                  <Form.Label>Street</Form.Label>
                  <Form.Control
                    type="text"
                    name="street"
                    value={values.street}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="input-field"
                  />
                </Form.Group>

                <Form.Group as={Col} md={4} controlId="city">
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    type="text"
                    name="city"
                    value={values.city}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="input-field"
                  />
                </Form.Group>

                <Form.Group as={Col} md={4} controlId="state">
                  <Form.Label>State</Form.Label>
                  <Form.Control
                    type="text"
                    name="state"
                    value={values.state}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="input-field"
                  />
                </Form.Group>

                <Form.Group as={Col} md={4} controlId="zipcode">
                  <Form.Label>Zip code</Form.Label>
                  <Form.Control
                    type="text"
                    name="zipcode"
                    value={values.zipcode}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="input-field"
                  />
                </Form.Group>
              <Form.Group as={Col} md={4} scontrolId="aadhar_number">
                <Form.Label>Aadhar Number</Form.Label>
                <Form.Control
                  type="text"
                  name="aadhar_number"
                  value={values.aadhar_number}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="input-field"
                />
                {/* {touched.aadhar_number && errors.aadhar_number && (submitted || touched.aadhar_number) && (
                  <Form.Control.Feedback type="invalid" tooltip>
                    {errors.aadhar_number}
                  </Form.Control.Feedback>
                )} */}
              </Form.Group>
              <Form.Group as={Col} md={4} scontrolId="pan_number">
                <Form.Label>PAN Number</Form.Label>
                <Form.Control
                  type="text"
                  name="pan_number"
                  value={values.pan_number}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="input-field"
                />
                {/* {touched.pan_number && errors.pan_number && (submitted || touched.pan_number) && (
                  <Form.Control.Feedback type="invalid" tooltip>
                    {errors.pan_number}
                  </Form.Control.Feedback>
                )} */}
              </Form.Group>
              <Form.Group as={Col} md={4} controlId="passport_number">
                <Form.Label>Passport Number</Form.Label>
                <Form.Control
                  type="text"
                  name="passport_number"
                  value={values.passport_number}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="input-field"
                />
                {/* {touched.passport_number && errors.passport_number && (submitted || touched.passport_number) && (
                  <Form.Control.Feedback type="invalid" tooltip>
                    {errors.passport_number}
                  </Form.Control.Feedback>
                )} */}
              </Form.Group>
              {/* <Form.Group as={Col} md={4} controlId="variable_pay">
                <Form.Label>Variable Pay</Form.Label>
                <Form.Control
                  type="text"
                  name="variable_pay"
                  value={values.variable_pay}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="input-field"
                />
              </Form.Group> */}
              {/* <Form.Group as={Col} md={4} controlId="fixed_pay">
                <Form.Label>Fixed Pay</Form.Label>
                <Form.Control
                  type="text"
                  name="fixed_pay"
                  value={values.fixed_pay}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="input-field"
                />
              </Form.Group> */}
              <Form.Group as={Col} md={4} scontrolId="salary">
                <Form.Label>Total Pay</Form.Label>
                <Form.Control
                  type="text"
                  name="salary"
                  value={values.salary}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="input-field"
                />
                {/* {touched.total_pay && errors.total_pay && (submitted || touched.total_pay) && (
                  <Form.Control.Feedback type="invalid" tooltip>
                    {errors.total_pay}
                  </Form.Control.Feedback>
                )} */}
              </Form.Group>
              {/* <Form.Group as={Col} md={4} controlId="salary">
                <Form.Label>Salary</Form.Label>
                <Form.Control
                  type="text"
                  name="salary"
                  value={values.salary}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="input-field"
                />
              </Form.Group> */}
              <Form.Group as={Col} md={4} scontrolId="emergency_contact_name">
                <Form.Label>Emergency Contact Name</Form.Label>
                <Form.Control
                  type="text"
                  name="emergency_contact_name"
                  value={values.emergency_contact_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="input-field"
                />
                {/* {touched.emergency_contact_name && errors.emergency_contact_name && (submitted || touched.emergency_contact_name) && (
                  <Form.Control.Feedback type="invalid" tooltip>
                    {errors.emergency_contact_name}
                  </Form.Control.Feedback>
                )} */}
              </Form.Group>
              <Form.Group as={Col} md={4} controlId="emergency_contact_relation">
                <Form.Label>Emergency Contact Relation</Form.Label>
                <Form.Control
                  type="text"
                  name="emergency_contact_relation"
                  value={values.emergency_contact_relation}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="input-field"
                />
                {/* {touched.emergency_contact_relation &&
                  errors.emergency_contact_relation &&
                  (submitted || touched.emergency_contact_relation) && (
                    <Form.Control.Feedback type="invalid" tooltip>
                      {errors.emergency_contact_relation}
                    </Form.Control.Feedback>
                  )} */}
              </Form.Group>
              <Form.Group as={Col} md={4} controlId="emergency_contact_number">
                <Form.Label>Emergency Contact Number</Form.Label>
                <div className="form-wrapper">
                  <Form.Control
                    type="text"
                    name="emergency_contact_number"
                    value={values.emergency_contact_number}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={submitted ? !!errors.emergency_contact_number : touched.emergency_contact_number && !!errors.emergency_contact_number}
                  />
                  {touched.emergency_contact_number && errors.emergency_contact_number && (submitted || touched.emergency_contact_number) && (
                    <Form.Control.Feedback type="invalid" tooltip>
                      {errors.emergency_contact_number}
                    </Form.Control.Feedback>
                  )}
                </div>
              </Form.Group>
              <Col md="12" className="button-wrapper">
                <Button type="submit" disabled={submitted}>
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EmployeeForm;
