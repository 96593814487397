import React from 'react';

function NoDataIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      viewBox="0 0 24 24"
      fill="none"
      stroke="#6c757d" // Adjust stroke color as needed
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <rect x="3" y="3" width="18" height="18" rx="2" ry="2" />
      <path d="M8 12h8M12 8v4" />
      <circle cx="12" cy="16" r="1" />
    </svg>
  );
}

export default NoDataIcon;