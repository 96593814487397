import React from 'react';
import Gravatar from 'react-gravatar';
import 'src/components/Avatar/avatar.scss';

const Avatar = ({ url, email, alt = '', noContainer = false }) => {
  return (
    <>
      {noContainer ? (
        <>
          {url ? (
            <img src={url} alt={alt} className="avatar" />
          ) : (
            <Gravatar alt={alt} email={email} size={100} default="identicon" className="avatar" />
          )}
        </>
      ) : (
        <div className="avatar-container">
          {url ? (
            <img src={url} alt={alt} className="avatar" />
          ) : (
            <Gravatar alt={alt} email={email} size={100} default="identicon" className="avatar" />
          )}
        </div>
      )}
    </>
  );
};

export default Avatar;
