import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Formik } from 'formik';
import { cloneDeep } from 'lodash';
import { Col, Form } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import useAuth from 'src/services/api';

const ApproveLeaveApplication = forwardRef(({ leaveID }, ref) => {
  const formikRef = useRef();
  const { approveLeaveRequest } = useAuth();
  const [submitted, setSubmitted] = useState(false);
  useImperativeHandle(ref, () => ({
    resetForm() {
      formikRef.current.resetForm();
    },
    submitForm() {
      console.log('triggered');
      formikRef.current.submitForm();
    }
  }));

  const onFormSubmit = async (values) => {
    setSubmitted(true);
    const subVal = cloneDeep(values);

    // if (subVal.note) {
    //   subVal.note = `Approval Reason: ${subVal.note}`;
    // }
    await approveLeaveRequest(leaveID, subVal);
    window.location.reload();
  };

  return (
    <Formik
      innerRef={formikRef}
      // validationSchema={schema}
      onSubmit={onFormSubmit}
      initialValues={{
        note: ''
      }}
    >
      {({ handleSubmit, handleChange, setFieldValue, handleBlur, values, touched, errors }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Form.Row>
            <Form.Group as={Col} md="12" controlId="validationFormik104">
              <Form.Label>Reson for leave approval:</Form.Label>

              <Form.Control
                as="textarea"
                name="note"
                rows={3}
                placeholder="Enter your reason here..."
                value={values.note}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={submitted ? !!errors.note : touched.note && !!errors.note}
              />

              {touched.note && errors.note && (submitted || touched.note) && (
                <Form.Control.Feedback type="invalid" tooltip>
                  {errors.note}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Form.Row>
        </Form>
      )}
    </Formik>
  );
});

export default ApproveLeaveApplication;
