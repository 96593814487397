import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import { createRoot } from 'react-dom/client';

import { Provider as StoreProvider } from 'react-redux';
import { ConfigProvider } from './contexts/ConfigContext';

import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './store';
import { ToastProvider } from 'react-toast-notifications';

const root = createRoot(document.getElementById('root'));

root.render(
  <StoreProvider store={store}>
    <ConfigProvider>
      <ToastProvider autoDismiss autoDismissTimeout={6000} placement="top-right">
        <App />
      </ToastProvider>
    </ConfigProvider>
  </StoreProvider>
);

reportWebVitals();
