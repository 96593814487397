import React, { useEffect, useState } from 'react';
import { Form, Col, Row, Image, Button, Card } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import './add-employee.scss'; // Import a custom CSS file for additional styling
import ActionIcons from 'src/components/ActionIcons/ActionIcons';
import { useNavigate, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import Loader from 'src/components/Loadify/Loader';
import usePermission from 'src/hooks/usePermission';
import useAuth from 'src/services/api';

const EmployeeForm = () => {
  const [submitted, setSubmitted] = useState(false);
  const [moduleAllowed, setModuleAllowed] = useState(false);
  const params = useParams();
  const { fetchEmployeeDetail, updateEmployeeDetail } = useAuth();
  const [employee, setEmployee] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [imageUrl, setImageUrl] = useState('');
  const { isSuperAdmin, isCurrentUser } = usePermission();
  const { addToast } = useToasts();
  const {fetchActiveDesignation } = useAuth();
  const [designations, setDesignations] = useState([]);
  const [designationTitle, setDesignationTitle] = useState('');

  useEffect(() => {
    const { empID } = params;
    const fetchData = async () => {
      try {
        const { data } = await fetchEmployeeDetail(empID);
        if (data?.employee) {
          const { employee } = data;
          Object.keys(employee).forEach((key) => {
            employee[key] = employee[key] === null ? '' : employee[key];
          });
          data.employee.name = `${employee.first_name} ${employee.last_name}`;
          data.employee.date_of_birth = employee.date_of_birth || '';
          data.employee.date_of_joining = employee.date_of_joining || '';
          data.employee.code = employee.code || '';
          data.employee.gender = employee.gender || 'male';
          data.employee.active = employee.active ? 'active' : 'inactive';
          data.employee.avatarURL = employee.avatar || '';
          data.employee.address_id = employee.address?.id;
          data.employee.street = employee.address?.street || '';
          data.employee.city = employee.address?.city || '';
          data.employee.state = employee.address?.state || '';
          data.employee.zipcode = employee.address?.zipcode || '';
          delete data.employee.avatar;
          delete data.employee.address;
          setEmployee(data?.employee);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        // Handle errors here
        console.error('Error fetching data:', error);
      }
    };
    // Call fetchData function when component mounts (on page load)
    fetchData();

    const isModuleAllowed = isSuperAdmin() || isCurrentUser(empID);
    setModuleAllowed(isModuleAllowed);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchActiveDesignation();

        if (response.data && response.data.designations && response.data.designations.length > 0) {
          setDesignations(response.data.designations);
        } else {
          console.error('No designations data found or data format is incorrect');
        }
      } catch (error) {
        // Handle errors here
        console.error('Error fetching data:', error);
      }
    };

    // Call fetchData function when component mounts (on page load)
    fetchData();
  }, []);

  useEffect(() => {
    const findDesignationTitle = () => {
      const designation = designations.find(d => d.id === employee?.employee_designations?.designation_id);
      if (designation) {
        setDesignationTitle(designation);
      }
    };

    if (designations.length > 0 && employee?.employee_designations?.designation_id) {
      findDesignationTitle();
    }
  }, [designations,  employee?.employee_designations?.designation_id]);

  const schema = Yup.object().shape({
    email: isSuperAdmin() ? Yup.string().email('Invalid email').required('Email is required') : Yup.string(),
    code: isSuperAdmin() ? Yup.string().required('Employee code is required').test('is-number', 'Employee code must be a number', value => {
      if (!value) return true;
      return /^\d+$/.test(value);
    }) : Yup.string(),
    // designation: isSuperAdmin() ? Yup.string().required('Designation is required') : Yup.string(),
    phone_number: Yup.string().required('Phone Number is required')
      .matches(/^[0-9]{10}$/, 'Phone Number must be a 10-digit number'),
    first_name: isSuperAdmin() ? Yup.string().required('First Name is required') : Yup.string(),
    last_name: isSuperAdmin() ? Yup.string().required('Last Name is required') : Yup.string(),
    date_of_joining: isSuperAdmin() ? Yup.string().required('Date of joining is required') : Yup.string(),
    date_of_birth: isSuperAdmin() ? Yup.string().required('Date of birth is required') : Yup.string(),
    nationality: isSuperAdmin() ? Yup.string().required('Nationality is required') : Yup.string(),
    personal_number: Yup.string().matches(/^[0-9]{10}$/, 'Phone Number must be a 10-digit number'),
    emergency_contact_number: Yup.string().matches(/^[0-9]{10}$/, 'Contact Number must be a 10-digit number'),
  });

  const handleAvatarClick = (e, values) => {
    e.preventDefault();
    !values.avatar && document.getElementById('avatarInput').click();
  };

  const handleRemoveImage = (e, setFieldValue) => {
    e.preventDefault();
    e.stopPropagation();
    console.log('handleImage');
    setFieldValue('avatarURL', '');
    setFieldValue('avatar', '');
    setImageUrl('');
  };

  const onFormSubmit = async (values) => {
    setSubmitted(true);
    // setting submitted
    const employeeId = employee.id;
    delete values.permissions;
    delete values.name;
    delete values.id;
    delete values.age;
    if (!isSuperAdmin()) {
      // delete values.first_name;
      // delete values.last_name;
      delete values.email;
      delete values.designation;
      delete values.date_of_joining;
      delete values.date_of_birth;
      delete values.code;
    }
    values.active = values.active === 'active';
    const { data } = await updateEmployeeDetail(employeeId, values);
    setSubmitted(false);
    if (data.errors) {
      for (let i = 0; i < data.errors.length; i++) {
        addToast(data.errors[i], {
          appearance: 'error',
          autoDismiss: true
        });
      }
    } else {
      addToast(`Employee Updated Successfully!`, {
        appearance: 'success',
        autoDismiss: true
      });
      setTimeout(() => {
        window.location.href = `/employee/${employee.id}`;
      }, 1500);
    }
  };

  const handleAvatarSelect = (event, setFieldValue) => {
    if (event.target.files.length > 0) {
      console.log('event.target.files[0] ', event.target.files[0]);
      setFieldValue('avatar', event.target.files[0]);
      const file = event.target.files[0];
      if (file) {
        // Create a new FileReader instance
        const reader = new FileReader();

        // Define a callback function to handle the FileReader's onload event
        reader.onload = () => {
          // Set the data URL as the image source
          setImageUrl(reader.result);
        };

        // Read the file as a data URL
        reader.readAsDataURL(file);
      }
    }
  };

  if (isLoading) <Loader />;
  if (!moduleAllowed) return <div className="col-12">You don't have access to this page</div>;
  if (moduleAllowed && Object.keys(employee).length > 0)
    return (
      <div className="col-12">
        <Formik validationSchema={schema} onSubmit={onFormSubmit} initialValues={{ ...employee }}>
          {({ handleSubmit, handleChange, handleBlur, setFieldValue, values, touched, isValid, errors }) => (
            <Form noValidate onSubmit={handleSubmit} className="employee-form">
              <Row>
                {/* className="flex-column" */}
                <Form.Group as={Col} controlId="avatar">
                  <div className="avatar-container">
                    <div className="avatar-wrapper" onClick={(e) => handleAvatarClick(e, values)}>
                      {(imageUrl || values.avatarURL) && (
                        <div>
                          <Image src={imageUrl || values.avatarURL} alt="Avatar" roundedCircle className="avatar-image" />
                          <div className="remove-image" onClick={(e) => handleRemoveImage(e, setFieldValue)}>
                            <ActionIcons type="cancel" />
                          </div>
                        </div>
                      )}
                      {!(imageUrl || values.avatarURL) && <div className="avatar-placeholder">Upload Profile Picture</div>}
                    </div>
                  </div>
                  {touched.avatar && errors.avatar && (submitted || touched.avatar) && (
                    <Form.Control.Feedback type="invalid" tooltip>
                      {errors.avatar}
                    </Form.Control.Feedback>
                  )}
                  {!values.avatar && (
                    <input
                      id="avatarInput"
                      type="file"
                      accept="image/*"
                      style={{ display: 'none' }}
                      onChange={(e) => handleAvatarSelect(e, setFieldValue)}
                    />
                  )}
                </Form.Group>

                {isSuperAdmin() && (
                  <Form.Group as={Col} md={4} controlId="active">
                    <Form.Label>User Status*</Form.Label>
                    <Form.Control
                      as="select"
                      name="active"
                      value={values.active}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="input-field"
                    >
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                    </Form.Control>
                  </Form.Group>
                )}

                <Form.Group as={Col} md={4} controlId="employee_code">
                  <Form.Label>Employee Code*</Form.Label>
                  <div className="form-wrapper">
                    <Form.Control
                      type="text"
                      name="code"
                      value={values.code}
                      disabled={!isSuperAdmin()}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={submitted ? !!errors.code : touched.code && !!errors.code}
                      className="input-field"
                    />
                    {touched.code && errors.code && (submitted || touched.code) && (
                      <Form.Control.Feedback type="invalid" tooltip>
                        {errors.code}
                      </Form.Control.Feedback>
                    )}
                  </div>
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="email">
                  <Form.Label>Email*</Form.Label>
                  <div className="form-wrapper">
                    <Form.Control
                      type="email"
                      name="email"
                      autoComplete="username"
                      value={values.email}
                      disabled={!isSuperAdmin()}
                      // disabled={true}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={submitted ? !!errors.email : touched.email && !!errors.email}
                    />

                    {touched.email && errors.email && (submitted || touched.email) && (
                      <Form.Control.Feedback type="invalid" tooltip>
                        {errors.email}
                      </Form.Control.Feedback>
                    )}
                  </div>
                </Form.Group>
                {
                  <Form.Group as={Col} md="4" controlId="first_name">
                    <Form.Label>First Name*</Form.Label>
                    <div className="form-wrapper">
                      <Form.Control
                        type="first_name"
                        name="first_name"
                        value={values.first_name}
                        // disabled={!isSuperAdmin()}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={submitted ? !!errors.first_name : touched.first_name && !!errors.first_name}
                      />
                      {touched.first_name && errors.first_name && (submitted || touched.first_name) && (
                        <Form.Control.Feedback type="invalid" tooltip>
                          {errors.first_name}
                        </Form.Control.Feedback>
                      )}
                    </div>
                  </Form.Group>
                }
                {
                  <Form.Group as={Col} md="4" controlId="last_name">
                    <Form.Label>Last Name*</Form.Label>
                    <div className="form-wrapper">
                      <Form.Control
                        type="last_name"
                        name="last_name"
                        value={values.last_name}
                        // disabled={!isSuperAdmin()}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={submitted ? !!errors.last_name : touched.last_name && !!errors.last_name}
                      />
                      {touched.last_name && errors.last_name && (submitted || touched.last_name) && (
                        <Form.Control.Feedback type="invalid" tooltip>
                          {errors.last_name}
                        </Form.Control.Feedback>
                      )}
                    </div>
                  </Form.Group>
                }

                {/* Add other form groups for Employee Code, Reports To, etc. */}

                {isSuperAdmin() && (
                  <Form.Group as={Col} md={4} controlId="date_of_joining">
                    <Form.Label>Date of Joining*</Form.Label>
                    <div className="custom-date-picker">
                      <Datetime
                        inputProps={{ name: 'date_of_joining', readOnly: true }}
                        value={values.date_of_joining}
                        onChange={(date) => setFieldValue('date_of_joining', date)}
                        timeFormat={false}
                        closeOnSelect={true}
                        className={`date-time-picker ${touched.date_of_joining && errors.date_of_joining ? 'is-invalid' : ''}`}
                      />
                      {touched.date_of_joining && errors.date_of_joining && (submitted || touched.date_of_joining) && (
                        <Form.Control.Feedback type="invalid" tooltip>
                          {errors.date_of_joining}
                        </Form.Control.Feedback>
                      )}
                      <span className="calendar-icon">
                        <i className="fa fa-calendar" aria-hidden="true"></i>
                      </span>
                    </div>
                  </Form.Group>
                )}

                {isSuperAdmin() && (
                  <Form.Group as={Col} md={4} controlId="designation">
                    <Form.Label>Designation*</Form.Label>
                    <div className="form-wrapper">
                      <Form.Control
                        as="select"
                        name="employee_designations.designation_id"
                        value={values.employee_designations.designation_id}
                        onChange={handleChange}
                        disabled={!isSuperAdmin()}
                        onBlur={handleBlur}
                        className="input-field"
                        isInvalid={submitted ? !!errors.designation : touched.designation && !!errors.designation}
                      >
                          {designations.map((designation) => (
                            <option key={designation.id} value={designation.id}>
                              {designation.title}
                            </option>
                          ))}
                          </Form.Control>

                      {touched.designation && errors.designation && (submitted || touched.designation) && (
                        <Form.Control.Feedback type="invalid" tooltip>
                          {errors.designation}
                        </Form.Control.Feedback>
                      )}
                    </div>
                  </Form.Group>
                )}

                {isSuperAdmin() && (
                  <Form.Group as={Col} md={4} controlId="date_of_birth">
                    <Form.Label>Date of Birth*</Form.Label>
                    <div className="custom-date-picker">
                      <Datetime
                        inputProps={{ name: 'date_of_birth', readOnly: true }}
                        value={values.date_of_birth}
                        onChange={(date) => setFieldValue('date_of_birth', date)}
                        timeFormat={false}
                        closeOnSelect={true}
                        className={`date-time-picker ${touched.date_of_birth && errors.date_of_birth ? 'is-invalid' : ''}`}
                      />
                      {touched.date_of_birth && errors.date_of_birth && (submitted || touched.date_of_birth) && (
                        <Form.Control.Feedback type="invalid" tooltip>
                          {errors.date_of_birth}
                        </Form.Control.Feedback>
                      )}
                      <span className="calendar-icon">
                        <i className="fa fa-calendar" aria-hidden="true"></i>
                      </span>
                    </div>
                  </Form.Group>
                )}
                {
                  <Form.Group as={Col} md={4} controlId="nationality">
                    <Form.Label>Nationality*</Form.Label>

                    <div className="form-wrapper">
                      <Form.Control
                        type="text"
                        name="nationality"
                        value={values.nationality}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="input-field"
                        isInvalid={submitted ? !!errors.nationality : touched.nationality && !!errors.nationality}
                      />
                      {errors.nationality && (submitted || touched.nationality) && (
                        <Form.Control.Feedback type="invalid" tooltip>
                          {errors.nationality}
                        </Form.Control.Feedback>
                      )}
                    </div>
                  </Form.Group>
                }

                {
                  <Form.Group as={Col} md={4} controlId="gender">
                    <Form.Label>Gender*</Form.Label>
                    <Form.Control
                      as="select"
                      name="gender"
                      value={values.gender}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="input-field"
                    >
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </Form.Control>
                  </Form.Group>
                }

                <Form.Group as={Col} md={4} controlId="phone_number">
                  <Form.Label>Phone Number*</Form.Label>
                  <div className="form-wrapper">
                    <Form.Control
                      type="text"
                      name="phone_number"
                      value={values.phone_number}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="input-field"
                      isInvalid={submitted ? !!errors.phone_number : touched.phone_number && !!errors.phone_number}
                    />
                    {touched.phone_number && errors.phone_number && (submitted || touched.phone_number) && (
                      <Form.Control.Feedback type="invalid" tooltip>
                        {errors.phone_number}
                      </Form.Control.Feedback>
                    )}
                  </div>
                </Form.Group>

                <Form.Group as={Col} md={4} controlId="street">
                  <Form.Label>Street</Form.Label>
                  <Form.Control
                    type="text"
                    name="street"
                    value={values.street}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="input-field"
                  />
                </Form.Group>

                <Form.Group as={Col} md={4} controlId="city">
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    type="text"
                    name="city"
                    value={values.city}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="input-field"
                  />
                </Form.Group>

                <Form.Group as={Col} md={4} controlId="state">
                  <Form.Label>State</Form.Label>
                  <Form.Control
                    type="text"
                    name="state"
                    value={values.state}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="input-field"
                  />
                </Form.Group>

                <Form.Group as={Col} md={4} controlId="zipcode">
                  <Form.Label>Zip code</Form.Label>
                  <Form.Control
                    type="text"
                    name="zipcode"
                    value={values.zipcode}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="input-field"
                  />
                </Form.Group>

                {isCurrentUser(params.empID) && (
                  <Form.Group as={Col} md={4} controlId="personal_email">
                    <Form.Label>Personal Email</Form.Label>
                    <Form.Control
                      type="text"
                      name="personal_email"
                      value={values.personal_email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="input-field"
                    />
                  </Form.Group>
                )}

                {isCurrentUser(params.empID) && (
                  <Form.Group as={Col} md={4} controlId="personal_number">
                    <Form.Label>Personal Number</Form.Label>
                    <div className="form-wrapper">
                      <Form.Control
                        type="text"
                        name="personal_number"
                        value={values.personal_number}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="input-field"
                        isInvalid={submitted ? !!errors.personal_number : touched.personal_number && !!errors.personal_number}
                      />
                      {touched.personal_number && errors.personal_number && (submitted || touched.personal_number) && (
                        <Form.Control.Feedback type="invalid" tooltip>
                          {errors.personal_number}
                        </Form.Control.Feedback>
                      )}
                    </div>
                  </Form.Group>
                )}
                <Form.Group as={Col} md={4} controlId="aadhar_number">
                  <Form.Label>Aadhar Number</Form.Label>
                  <Form.Control
                    type="text"
                    name="aadhar_number"
                    value={values.aadhar_number}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="input-field"
                  />
                </Form.Group>
                <Form.Group as={Col} md={4} controlId="pan_number">
                  <Form.Label>PAN Number</Form.Label>
                  <Form.Control
                    type="text"
                    name="pan_number"
                    value={values.pan_number}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="input-field"
                  />
                </Form.Group>
                <Form.Group as={Col} md={4} controlId="passport_number">
                  <Form.Label>Passport Number</Form.Label>
                  <Form.Control
                    type="text"
                    name="passport_number"
                    value={values.passport_number}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="input-field"
                  />
                </Form.Group>
                {/* {isSuperAdmin() && (
                  <Form.Group as={Col} md={4} controlId="variable_pay">
                    <Form.Label>Variable Pay</Form.Label>
                    <Form.Control
                      type="text"
                      name="variable_pay"
                      value={values.variable_pay}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="input-field"
                    />
                  </Form.Group>
                )} */}
                {/* {isSuperAdmin() && (
                  <Form.Group as={Col} md={4} controlId="fixed_pay">
                    <Form.Label>Fixed Pay</Form.Label>
                    <Form.Control
                      type="text"
                      name="fixed_pay"
                      value={values.fixed_pay}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="input-field"
                    />
                  </Form.Group>
                )} */}
                {isSuperAdmin() && (
                  <Form.Group as={Col} md={4} controlId="salary">
                    <Form.Label>Total Pay</Form.Label>
                    <Form.Control
                      type="text"
                      name="salary"
                      value={values.salary}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="input-field"
                    />
                  </Form.Group>
                )}
                {/* {isSuperAdmin() && (
                  <Form.Group as={Col} md={4} controlId="salary">
                    <Form.Label>Salary pay frequency</Form.Label>
                    <Form.Control
                      type="text"
                      name="salary"
                      value={values.salary}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="input-field"
                    />
                  </Form.Group>
                )} */}
                <Form.Group as={Col} md={4} controlId="emergency_contact_name">
                  <Form.Label>Emergency Contact Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="emergency_contact_name"
                    value={values.emergency_contact_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="input-field"
                  />
                  {/* {touched.emergency_contact_name && errors.emergency_contact_name && (submitted || touched.emergency_contact_name) && (
                  <Form.Control.Feedback type="invalid" tooltip>
                    {errors.emergency_contact_name}
                  </Form.Control.Feedback>
                )} */}
                </Form.Group>
                <Form.Group as={Col} md={4} controlId="emergency_contact_relation">
                  <Form.Label>Emergency Contact Relation</Form.Label>
                  <Form.Control
                    type="text"
                    name="emergency_contact_relation"
                    value={values.emergency_contact_relation}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="input-field"
                  />
                  {/* {touched.emergency_contact_relation &&
                  errors.emergency_contact_relation &&
                  (submitted || touched.emergency_contact_relation) && (
                    <Form.Control.Feedback type="invalid" tooltip>
                      {errors.emergency_contact_relation}
                    </Form.Control.Feedback>
                  )} */}
                </Form.Group>
                <Form.Group as={Col} md={4} controlId="emergency_contact_number">
                  <Form.Label>Emergency Contact Number</Form.Label>
                  <div className="form-wrapper">
                    <Form.Control
                      type="text"
                      name="emergency_contact_number"
                      value={values.emergency_contact_number}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={submitted ? !!errors.emergency_contact_number : touched.emergency_contact_number && !!errors.emergency_contact_number}
                    />
                    {touched.emergency_contact_number && errors.emergency_contact_number && (submitted || touched.emergency_contact_number) && (
                      <Form.Control.Feedback type="invalid" tooltip>
                        {errors.emergency_contact_number}
                      </Form.Control.Feedback>
                    )}
                  </div>
                </Form.Group>
                <Col md="12" className="button-wrapper">
                  <Button type="submit" disabled={submitted}>
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </div>
    );
};

export default EmployeeForm;
