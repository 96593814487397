import keys from 'lodash/keys';
import { useSelector } from 'react-redux';
import usePermission from './hooks/usePermission';

const getSidebarLinks = (permissions, isModuleAccessible) => {
  const allowedMenuItems = keys(permissions);
  const LINKS = [
    {
      id: 'dashboard',
      title: 'Dashboard',
      type: 'item',
      url: '/dashboard',
      icon: 'feather icon-home'
    },
    {
      id: 'employees',
      title: 'Employees',
      type: 'item',
      url: '/employees',
      icon: 'feather icon-users'
    },
    {
      id: 'payslips',
      title: 'Payslips',
      type: 'item',
      url: '/payslips',
      icon: 'far fa-file-alt'
    }
  ];

  // if (allowedMenuItems.includes('employees')) {
  //   // Push leave policy under Leave Panel
  //   LINKS.push({
  //     id: 'employees',
  //     title: 'Employees',
  //     type: 'item',
  //     url: '/employees',
  //     icon: 'feather icon-users'
  //   });
  // }

  if (['leave_policy', 'leave_requests', 'leave_application'].some((item) => allowedMenuItems.includes(item) && permissions[item]?.view)) {
    LINKS.push({
      id: 'leave_panel',
      title: 'Leave Panel',
      type: 'group',
      children: []
    });
  }
  if (isModuleAccessible('leave_policy', 'view')) {
    // Push leave policy under Leave Panel
    let leavePanelIndex = LINKS.findIndex((item) => item.id === 'leave_panel');
    if (leavePanelIndex !== -1)
      LINKS[leavePanelIndex]?.children.push({
        id: 'leave_policy',
        title: 'Leave Policy',
        type: 'item',
        url: '/leave-policy',
        icon: 'fas fa-book'
      });
  }
  if (isModuleAccessible('leave_requests', 'view')) {
    // Push leave requests under Leave Panel
    let leavePanelIndex = LINKS.findIndex((item) => item.id === 'leave_panel');
    if (leavePanelIndex !== -1)
      LINKS[leavePanelIndex]?.children.push({
        id: 'leave_requests',
        title: 'Leave Requests',
        type: 'item',
        url: '/leave-requests',
        icon: 'fas fa-calendar-plus'
      });
  }
  if (isModuleAccessible('leave_application', 'view')) {
    // Push leave application under Leave Panel
    let leavePanelIndex = LINKS.findIndex((item) => item.id === 'leave_panel');
    if (leavePanelIndex !== -1)
      LINKS[leavePanelIndex]?.children.push({
        id: 'leave_application',
        title: 'Leave Applications',
        type: 'item',
        url: '/leave-application',
        icon: 'far fa-file-alt'
      });
  }
  return LINKS;
};

const SideMenuItems = () => {
  const { user } = useSelector((state) => state.auth);
  const { isModuleAccessible } = usePermission();
  const sidebarItems = getSidebarLinks(user?.permissions, isModuleAccessible);
  return { items: sidebarItems };
};

export default SideMenuItems;
