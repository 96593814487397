import { combineReducers } from '@reduxjs/toolkit';
import { reducer as formReducer } from 'redux-form';
import authSlice from './slices/authSlice';

const reducers = combineReducers({
  form: formReducer,
  auth: authSlice
});

export default reducers;
