import 'src/components/ActionIcons/action-icons.scss';
import { IconMapper } from '../IconMapper';

const ActionIcons = ({ type = 'add', url = '', target = '_self', onClick = () => {}, title = '' }) => {
  const actionIconClick = () => {
    if (url) {
      window.open(url, target);
    } else {
      onClick();
    }
  };

  return (
    <span className="action-icon" onClick={actionIconClick} title={title}>
      <i className={IconMapper[type]} />
    </span>
  );
};

export default ActionIcons;
