import { useState } from 'react';
import { Button, Card, Modal } from 'react-bootstrap';

const ActionModal = ({ show = false, title = {}, centered = false, body, onHide = () => {}, onCancel = () => {}, onSubmit = () => {} }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSubmit = () => {
    setIsSubmitting(true);
    onSubmit();
  };
  return (
    <>
      <Modal show={show} centered={centered} onHide={onHide} aria-labelledby={centered && 'contained-modal-title-vcenter'}>
        <Card style={{ marginBottom: 0 }}>
          <Card.Header>
            <button type="button" className="close" onClick={onHide}>
              <span aria-hidden="true">&times;</span>
            </button>
            <Card.Title as="h5">{title}</Card.Title>
          </Card.Header>
          <Card.Body>{body}</Card.Body>
        </Card>
        <Modal.Footer>
          <Button onClick={onCancel} disabled={isSubmitting}>
            Cancel
          </Button>
          <Button onClick={handleSubmit} disabled={isSubmitting}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ActionModal;
