import { useSelector } from 'react-redux';

const usePermission = () => {
  const { user } = useSelector((state) => state.auth);

  const isModuleAccessible = (module, action = 'view') => {
    const { permissions } = user;
    return permissions?.[module]?.[action] ? permissions?.[module]?.[action] : false;
  };

  const isSuperAdmin = () => {
    return user?.isSuperAdmin;
  };

  const isCurrentUser = (id) => {
    return user.id.toString() === id.toString();
  };

  return {
    isModuleAccessible,
    isSuperAdmin,
    isCurrentUser
  };
};

export default usePermission;
