import React from 'react';

const ActionLink = ({ url, title = '', target = '_self', children }) => {
  return (
    <a href={url} title={title} target={target} rel="noopener noreferrer">
      {children}
    </a>
  );
};

export default ActionLink;
