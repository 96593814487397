import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Alert, Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import * as Yup from 'yup';
import { Formik } from 'formik';
// import useAuth from '../../../hooks/useAuth';
import useScriptRef from '../../../hooks/useScriptRef';
import Waves from 'src/components/backgrounds/Waves';
import Breadcrumb from '../../../layouts/AdminLayout/Breadcrumb';
import { login } from 'src/store/slices/authSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import Loader from 'src/components/Loadify/Loader';
import useAuth from 'src/services/api';
import { useToasts } from 'react-toast-notifications';

const Login = ({ className, ...rest }) => {
  const scriptedRef = useScriptRef();
  const { userLogin }  = useAuth();
  const { isLoggedIn } = useSelector((state) => state.auth);
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [redirectPath, setRedirectPath] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const { addToast } = useToasts();

  useEffect(() => {
    // Store the previous path when user is redirected to login page
    if (isLoggedIn) {
      navigate('/dashboard');
    }
    setIsLoading(false);
    if (!isLoggedIn && redirectPath !== location.pathname) {
      setRedirectPath(location.pathname);
    }
  }, [isLoggedIn, redirectPath, location.pathname, navigate]);
  // const { firebaseEmailPasswordSignIn, firebaseGoogleSignIn } = useAuth();

  const handleLogin = async (organization_code, email, password) => {
    try {
      // integrate api
      const { data, headers } = await userLogin({ organization_code, email, password });

      if(!data.success){
        addToast(data.message, {
          appearance: 'error',
          autoDismiss: true
        });
        
      } else {
        addToast(`Logged In Successfully!`, {
          appearance: 'success',
          autoDismiss: true
        });
      }

      if (headers?.['x-authentication-token']) {
        localStorage.setItem('token', headers?.['x-authentication-token']);
        localStorage.setItem('organization_code', data?.organization_code);
        const { employee } = data?.user;
        const employeeData = {
          isSuperAdmin: employee?.super_admin,
          avatar: employee?.avatar,
          first_name: employee?.first_name,
          last_name: employee?.last_name,
          id: employee?.id,
          email: employee?.email,
          permissions: employee?.permissions
        };
        dispatch(login(employeeData));

        // Navigate back to the previous URL or dashboard if the previous path was the login page
        if (redirectPath === '/login') {
          navigate('/dashboard'); // Redirect to dashboard after login if the previous URL was the login page
        } else {
          navigate(redirectPath || '/'); // If no previous path or not the login page, go to home ('/')
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  if (isLoading) return <Loader />;
  return (
    <React.Fragment>
      <Waves />
      <Breadcrumb />
      <div className="auth-wrapper">
        <div className="auth-content">
          <div className="auth-b">
            <span className="r" />
            <span className="r s" />
            <span className="r s" />
            <span className="r" />
          </div>
          <Card className="borderless text-center">
            <Card.Body>
              <div className="">
                <i className="feather icon-unlock auth-icon" />
              </div>
              <div className="mb-4">
                <div className="form-title">Lean HR</div>
              </div>
              <Formik
                initialValues={{
                  // email: 'dummy@gmail.com',
                  // password: 'password',
                  email: '',
                  password: '',
                  organization_code: '',
                  submit: null
                }}
                validationSchema={Yup.object().shape({
                  email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                  password: Yup.string().max(255).required('Password is required')
                })}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                  try {
                    // await firebaseEmailPasswordSignIn(values.email, values.password);

                    await handleLogin(values.organization_code, values.email, values.password);
                    if (scriptedRef.current) {
                      setStatus({ success: true });
                      setSubmitting(true);
                    }
                  } catch (err) {
                    console.error(err);
                    if (scriptedRef.current) {
                      setStatus({ success: false });
                      setErrors({ submit: err.message });
                      setSubmitting(false);
                    }
                  }
                }}
              >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                  <form noValidate onSubmit={handleSubmit} className={className} {...rest}>
                  <div className="form-group mb-3">
                    <input
                      className="form-control"
                      error={touched.organization_code && errors.organization_code}
                      label="Organization Code"
                      name="organization_code"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      placeholder='Enter Organization Code'
                      value={values.organization_code}
                    />
                    {touched.organization_code && errors.organization_code && <small className="text-danger form-text">{errors.organization_code}</small>}
                  </div>
                    <div className="form-group mb-3">
                      <input
                        className="form-control"
                        error={touched.email && errors.email}
                        label="Email Address / Username"
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="email"
                        placeholder='Enter Email'
                        value={values.email}
                      />
                      {touched.email && errors.email && <small className="text-danger form-text">{errors.email}</small>}
                    </div>
                    <div className="form-group mb-4">
                      <input
                        className="form-control"
                        error={touched.password && errors.password}
                        label="Password"
                        name="password"
                        placeholder='Enter Password'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="password"
                        value={values.password}
                      />
                      {touched.password && errors.password && <small className="text-danger form-text">{errors.password}</small>}
                    </div>

                    {errors.submit && (
                      <Col sm={12}>
                        <Alert variant="danger">{errors.submit}</Alert>
                      </Col>
                    )}

                    {/* <div className="custom-control custom-checkbox  text-left mb-4 mt-2">
                      <input type="checkbox" className="custom-control-input" id="customCheck1" />
                      <label className="custom-control-label" htmlFor="customCheck1">
                        Save credentials.
                      </label>
                    </div> */}

                    <Row>
                      <Col mt={2}>
                        <Button className="btn-block" color="primary" disabled={isSubmitting} size="large" type="submit" variant="primary">
                          Log In
                        </Button>
                      </Col>
                    </Row>
                  </form>
                )}
              </Formik>

              {/* <Row>
        <Col sm={12}>
          <h5 className="my-3"> OR </h5>
        </Col>
      </Row>

      <Row>
        <Col sm={12}>
          <Button onClick={googleHandler} variant="danger">
            <i className="fa fa-lock" /> Sign in with Google
          </Button>
        </Col>
      </Row> */}

              {/* <hr /> */}
            </Card.Body>
          </Card>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Login;
