import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Col, Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { cloneDeep } from 'lodash';
import useAuth from 'src/services/api';
import { useToasts } from 'react-toast-notifications';
import { parseDate } from 'src/utils/common';

const schema = Yup.object().shape({
  start_date: Yup.string().required(),
  end_date: Yup.string().required(),
  duration: Yup.string().required(),
  description: Yup.string().required(),
  leave_type: Yup.string().required()
});

const ApplyForLeave = forwardRef((props, ref) => {
  const formikRef = useRef();
  const { applyLeave } = useAuth();
  const { addToast } = useToasts();
  const [showDurationDropDown, setShowDurationDropDown] = useState(false);
  useImperativeHandle(ref, () => ({
    resetForm() {
      formikRef.current.resetForm();
    },
    submitForm() {
      formikRef.current.submitForm();
    }
  }));

  const onFormSubmit = async (values) => {
    props.setSubmitted(true);
    const subVal = cloneDeep(values);
    const startDate = new Date(subVal.start_date);
    const endDate = new Date(subVal.end_date);

    const formattedStartDate = `${String(startDate.getDate()).padStart(2, '0')}/${String(startDate.getMonth() + 1).padStart(
      2,
      '0'
    )}/${startDate.getFullYear()}`;
    const formattedEndDate = `${String(endDate.getDate()).padStart(2, '0')}/${String(endDate.getMonth() + 1).padStart(
      2,
      '0'
    )}/${endDate.getFullYear()}`;

    subVal.start_date = formattedStartDate;
    subVal.end_date = formattedEndDate;
    subVal.note = values.description;
    // subVal.note_attributes = { description: values.description };
    delete subVal.description;

    if (parseDate(subVal.start_date) > parseDate(subVal.end_date)) {
      addToast('Start date should be less than End date', {
        appearance: 'error',
        autoDismiss: true,
      });
      return;
    }

    const { data } = await applyLeave(subVal);
    props.closeModal();
    if (data.success) {
      addToast(`Leave Applied Successfully!`, {
        appearance: 'success',
        autoDismiss: true
      });
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } else {
      if (data.errors) {
        let errors = data.errors[0] || data.errors.base[0];
        addToast(errors, {
          appearance: 'error',
          autoDismiss: true
        });
      }
    }
    setTimeout(() => {
      window.location.reload();
    }, 2500);
  };

  const compareDates = (date1, date2) => {
    const formattesDateA = `${String(date1.getDate()).padStart(2, '0')}-${String(date1.getMonth() + 1).padStart(
      2,
      '0'
    )}-${date1.getFullYear()}`;
    const formattesDateB = `${String(date2.getDate()).padStart(2, '0')}-${String(date2.getMonth() + 1).padStart(
      2,
      '0'
    )}-${date2.getFullYear()}`;
    if (formattesDateA === formattesDateB) {
      setShowDurationDropDown(true);
    } else {
      setShowDurationDropDown(false);
    }
  };

  return (
    <Formik
      innerRef={formikRef}
      validationSchema={schema}
      onSubmit={onFormSubmit}
      initialValues={{
        description: '',
        end_date: '',
        start_date: '',
        duration: 'full',
        leave_type: 'casual'
      }}
    >
      {({ handleSubmit, handleChange, setFieldValue, handleBlur, values, touched, isValid, errors }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Form.Row>
            <Form.Group as={Col} md="12" controlId="validationFormik106">
              <Form.Label>Start Date *</Form.Label>
              <br />
              <DatePicker
                name="start_date"
                selected={values.start_date}
                onChange={(date) => {
                  if (values.end_date) {
                    compareDates(date, values.end_date);
                  }
                  setFieldValue('start_date', date);
                }}
                autoComplete="off"
                shouldCloseOnSelect={true}
                closeOnSelect={true}
                className={`form-control ${touched.start_date && errors.start_date ? 'is-invalid' : ''}`}
              />

              {touched.start_date && errors.start_date && (props.submitted || touched.start_date) && (
                <Form.Control.Feedback type="invalid" tooltip>
                  {errors.start_date}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group as={Col} md="12" controlId="validationFormik103">
              <Form.Label>End Date *</Form.Label>
              <br />
              <DatePicker
                name="end_date"
                selected={values.end_date}
                onChange={(date) => {
                  if (values.start_date) {
                    compareDates(date, values.start_date);
                  }
                  setFieldValue('end_date', date);
                }}
                autoComplete="off"
                shouldCloseOnSelect={true}
                closeOnSelect={true}
                className={`form-control ${touched.end_date && errors.end_date ? 'is-invalid' : ''}`}
              />

              {touched.end_date && errors.end_date && (props.submitted || touched.end_date) && (
                <Form.Control.Feedback type="invalid" tooltip>
                  {errors.end_date}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            {showDurationDropDown && (
              <Form.Group as={Col} md="12" controlId="validationFormik105">
                <Form.Label>Duration *</Form.Label>
                <Form.Control
                  as="select"
                  name="duration"
                  value={values.duration}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={props.submitted ? !!errors.duration : touched.duration && !!errors.duration}
                >
                  <option value="half">Half</option>
                  <option value="full">Full</option>
                </Form.Control>

                {touched.duration && errors.duration && (props.submitted || touched.duration) && (
                  <Form.Control.Feedback type="invalid" tooltip>
                    {errors.duration}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            )}
            <Form.Group as={Col} md="12" controlId="validationFormik105">
              <Form.Label>Leave Type *</Form.Label>
              <Form.Control
                as="select"
                name="leave_type"
                value={values.leave_type}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={props.submitted ? !!errors.leave_type : touched.leave_type && !!errors.leave_type}
              >
                <option value="casual">Casual</option>
                <option value="sick">Sick</option>
                {/* <option value="wfh">Work From Home</option>
                <option value="misc">Miscellaneous</option> */}
              </Form.Control>

              {touched.leave_type && errors.leave_type && (props.submitted || touched.leave_type) && (
                <Form.Control.Feedback type="invalid" tooltip>
                  {errors.leave_type}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group as={Col} md="12" controlId="validationFormik104">
              <Form.Label>Reason *</Form.Label>

              <Form.Control
                as="textarea"
                name="description"
                rows={3}
                placeholder="Enter your reason here..."
                value={values.description}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={props.submitted ? !!errors.description : touched.description && !!errors.description}
              />

              {touched.description && errors.description && (props.submitted || touched.description) && (
                <Form.Control.Feedback type="invalid" tooltip>
                  {errors.description}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Form.Row>
          {/* <Card.Footer style={{ display: 'flex', gap: 8, justifyContent: 'flex-end', padding:0, paddingTop: 8 }}>
              <Button variant="outline-primary" style={{ marginRight: 0 }}>
                Reset
              </Button>
              <Button type="submit" style={{ marginRight: 0 }}>
                Submit
              </Button>
          </Card.Footer> */}
        </Form>
      )}
    </Formik>
  );
});

export default ApplyForLeave;
