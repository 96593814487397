import React, { useEffect, useRef, useState } from 'react';
import { Row, Col, Card, Table, Button } from 'react-bootstrap';
import FormModal from 'src/components/FormModal/FormModal';
import useAuth from 'src/services/api';
import ActionIcons from 'src/components/ActionIcons/ActionIcons';
import ActionModal from 'src/components/ActionModal/ActionModal';
import ApplyForLeave from './ApplyForLeave';
import { capitalize } from 'lodash';
import { LEAVE_ASSIGNED_TYPE, LEAVE_REQUEST_TYPE } from 'src/config/constant';
import 'src/views/LeaveRequests/leave-requests.scss';
import UpdateLeaveRequest from './UpdateLeaveRequest';
import InfoPopup from 'src/components/InfoPopup/InfoPopup';
import { truncateString } from 'src/utils/common';
import { NoData } from 'src/components/NoData/NoData';
import Loader from 'src/components/Loadify/Loader';

const LeaveRequests = () => {
  const { cancelLeaveRequest, fetchUserLeaveRequests } = useAuth();
  const [showFormModal, setSetShowFormModal] = useState(false);
  const [submitted, setSubmitted] = useState(false);;
  const [showUpdateFormModal, setShowUpdateFormModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedRequestID, setSelectedRequestID] = useState(null);
  const [allLeaveRequests, setAllLeaveRequests] = useState([]);
  const [leaveBalance, setLeaveBalance] = useState({ casual: 0, sick: 0 });
  const formikRef = useRef(null);
  const updateFormikRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Replace 'your_api_endpoint' with your actual API endpoint
        const { data } = await fetchUserLeaveRequests();
        console.log('datadatadatadata', data?.leave_transactions);

        setAllLeaveRequests(data?.leave_transactions); // Update state with fetched data
        setLeaveBalance((prev) => ({ ...prev, ...data?.meta?.leave_balances }));
        setIsLoading(false);
      } catch (error) {
        // Handle errors here
        console.error('Error fetching data:', error);
        setIsLoading(false);
      }
    };

    // Call fetchData function when component mounts (on page load)
    fetchData();
  }, []);

  const onAddPolicy = () => {
    setSetShowFormModal(true);
  };
  const hideFormModal = () => {
    setSetShowFormModal(false);
  };
  const onUpdateRequest = (id) => {
    setSelectedRequestID(id);
    setShowUpdateFormModal(true);
  };
  const hideUpdateFormModal = () => {
    setShowUpdateFormModal(false);
  };
  const hideCancelFormModal = () => {
    setShowCancelModal(false);
  };

  const onReset = () => {
    formikRef?.current?.resetForm();
  };

  const onSubmit = () => {
    formikRef?.current?.submitForm();
  };

  const onUpdateCancel = () => {
    updateFormikRef?.current?.resetForm();
  };

  const onUpdateSubmit = () => {
    console.log('submitForm');
    updateFormikRef?.current?.submitForm();
  };

  const onCancelPolicy = (id) => {
    setSelectedRequestID(id);
    setShowCancelModal(true);
  };

  const onCancelClick = async () => {
    // Cancel selected ploicy
    await cancelLeaveRequest(selectedRequestID);
    window.location.reload();
  };

  if (isLoading) return <Loader />;
  if (allLeaveRequests)
    return (
      <React.Fragment>
        <Row>
          <Col>
            <div className="leave-rq-container">
              <div className="rq-balance">
                <Table bordered striped hover style={{ maxWidth: '200px', backgroundColor: '#fff' }}>
                  <thead>
                    <tr>
                      <th>Leave Type</th>
                      <th>Balance</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <strong>Sick Leave</strong>
                      </td>
                      <td>{leaveBalance?.sick}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Casual Leave</strong>
                      </td>
                      <td>{leaveBalance?.casual}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Unpaid Leave</strong>
                      </td>
                      <td>{leaveBalance?.unpaid}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <div className="rq-apply">
                <Button variant="outline-primary" style={{ marginRight: 0 }} onClick={onAddPolicy}>
                  <i className="fas fa-plus" />
                  Apply for Leave
                </Button>
                <FormModal
                  show={showFormModal}
                  onHide={hideFormModal}
                  title={'New Leave Application'}
                  onReset={onReset}
                  onSubmit={onSubmit}
                  submitted={submitted}
                  body={<ApplyForLeave
                     ref={formikRef}
                     closeModal={hideFormModal}
                     submitted={submitted}
                     setSubmitted={setSubmitted} />}
                />
                <FormModal
                  show={showUpdateFormModal}
                  onHide={hideUpdateFormModal}
                  title={'Update Leave Requests'}
                  onReset={onUpdateCancel}
                  onSubmit={onUpdateSubmit}
                  submitted={submitted}
                  body={<UpdateLeaveRequest
                     ref={updateFormikRef}
                     leaveRequestID={selectedRequestID}
                     submitted={submitted} setSubmitted={setSubmitted}/>}
                />
                <ActionModal
                  show={showCancelModal}
                  onHide={hideCancelFormModal}
                  title={'Cancel Leave Request'}
                  onCancel={hideCancelFormModal}
                  onSubmit={onCancelClick}
                  body={'Are you sure you want to cancel this request?'}
                />
              </div>
            </div>
            {allLeaveRequests.length === 0 ? <NoData text='No leave request added yet' /> :
              <Card>
                <Card.Header>
                  <Card.Title as="h5">Leave Requests</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Table responsive hover>
                    <thead>
                      <tr>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Leave Type</th>
                        <th>Duration</th>
                        <th>Leave Reason</th>
                        <th>Status</th>
                        <th>Approved/Rejected By</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allLeaveRequests.map((leave, index) => {
                        const startDate = new Date(leave.start_date);
                        const endDate = new Date(leave.end_date);
                        const formattedStartDate = `${String(startDate.getUTCDate()).padStart(2, '0')}/${String(
                          startDate.getUTCMonth() + 1
                        ).padStart(2, '0')}/${startDate.getUTCFullYear()}`;
                        const formattedEndDate = `${String(endDate.getUTCDate()).padStart(2, '0')}/${String(
                          endDate.getUTCMonth() + 1
                        ).padStart(2, '0')}/${endDate.getUTCFullYear()}`;
                        return (
                          <tr key={index}>
                            <td>{formattedStartDate}</td>
                            <td>{formattedEndDate}</td>
                            <td>{LEAVE_ASSIGNED_TYPE[leave.leave_type]}</td>
                            <td>{capitalize(leave.duration)}</td>
                            <td>
                              {leave.note?.description?.length > 15 ? <>{truncateString(leave.note?.description, 15)}
                                <InfoPopup type='circleInfo' text={leave.note?.description} />
                              </> : leave.note?.description}
                            </td>
                            <td><span>{LEAVE_REQUEST_TYPE[leave.status]}</span>
                              {['approved', 'rejected'].includes(leave.status) &&
                                (<InfoPopup type='circleInfo' align='right' text={leave.status === 'approved' ? leave.approved_by?.note?.description
                                  : leave.rejected_by?.note?.description} />)}</td>
                            <td style={{ cursor: 'pointer' }}>
                              <span>{leave.status === 'cancelled' && 'self'}</span>
                              {leave.status === 'rejected' && (
                                <span>{leave.rejected_by.name}</span>
                              )}
                              {leave.status === 'approved' && (
                                <span>{leave.approved_by.name}</span>
                              )}
                            </td>
                            {['approved', 'rejected', 'cancelled'].includes(leave.status) ? (
                              <td></td>
                            ) : (
                              <td style={{ display: 'flex', gap: 8 }}>
                                <ActionIcons type="edit" title="Update your Request" onClick={() => onUpdateRequest(leave.id)} />
                                <ActionIcons type="delete" title="Cancel request" onClick={() => onCancelPolicy(leave.id)} />
                              </td>
                            )}
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>}
          </Col>
        </Row>
      </React.Fragment>
    );
};

export default LeaveRequests;
