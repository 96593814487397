import { useState } from "react";
import { IconMapper } from "../IconMapper";
import 'src/components/InfoPopup/info-popup.scss';

const InfoPopup = ({ type = 'info', align = 'left', text = '' }) => {
    const [showPopup, setShowPopup] = useState(false);
  
    return (
      <div className="info-popup">
        <span onClick={() => setShowPopup(!showPopup)}
         onMouseEnter={() => setShowPopup(true)}
         onMouseLeave={() => setShowPopup(false)}
        >
            <i className={IconMapper[type]} />
        </span>
        {showPopup && (
          <div className={`popup ${align==="right" && 'right'}`}>
            <div className="popup-content">
              {text}
            </div>
          </div>
        )}
      </div>
    );
  }
  
  export default InfoPopup;