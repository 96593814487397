import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import { Col, Form, FormCheck } from 'react-bootstrap';
import useAuth from 'src/services/api';

const schema = Yup.object().shape({});

const AssignLeavePolicy = forwardRef(({ policyID }, ref) => {
  const formikRef = useRef();
  const { assignEmployees, fetchEmployeeList } = useAuth();
  const [employeeList, setEmployeeList] = useState([]);
  const [checkboxOptions, setCheckboxOptions] = useState({});
  const [submitted, setSubmitted] = useState(false);
  useImperativeHandle(ref, () => ({
    resetForm() {
      formikRef.current.resetForm();
    },
    submitForm() {
      formikRef.current.submitForm();
    }
  }));

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Replace 'your_api_endpoint' with your actual API endpoint
        const { data } = await fetchEmployeeList(policyID);
        console.log('manager', data?.employees);
        setEmployeeList(data?.employees); // Update state with fetched data
        const ckOptions = data?.employees.reduce((acc, { id, is_assigned }) => {
          acc[id] = is_assigned;
          return acc;
        }, {});
        setCheckboxOptions(ckOptions);
      } catch (error) {
        // Handle errors here
        console.error('Error fetching data:', error);
      }
    };

    // Call fetchData function when component mounts (on page load)
    fetchData();
  }, []);

  const onFormSubmit = async (values) => {
    console.log(values);
    if (values) {
      await assignEmployees(policyID, { employees: values });
    }
    setSubmitted(true);
    window.location.reload();
  };
  if (employeeList && Object.keys(checkboxOptions).length > 0)
    return (
      <Formik innerRef={formikRef} onSubmit={onFormSubmit} initialValues={checkboxOptions}>
        {({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Row>
              <Form.Group as={Col} md="12" controlId="validationFormik101">
                {employeeList.length > 0 &&
                  employeeList.map((employee) => {
                    console.log('checked ', employee.name, values);
                    return (
                      <FormCheck key={employee.id}>
                        <Field id={employee.id.toString()} name={employee.id.toString()} type="checkbox" className="form-check-input" />
                        <FormCheck.Label htmlFor={employee.id.toString()} className="form-check-label">
                          {employee.name}
                        </FormCheck.Label>
                      </FormCheck>
                    );
                  })}
              </Form.Group>
            </Form.Row>
          </Form>
        )}
      </Formik>
    );
});

export default AssignLeavePolicy;
