import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Dashboard from '../views/dashboard/DashDefault';
import AdminLayout from '../layouts/AdminLayout';
import Login from '../views/auth/signin/Login';
import SignUp from '../views/auth/signup/SignUp1';
import Error404 from '../views/error/default/Error404';
import { BASENAME } from '../config/constant';
import AuthenticatedRoutes from './AuthenticatedRoutes';
import useAuth from 'src/services/api';
import { updateUser } from 'src/store/slices/authSlice';
import Loader from 'src/components/Loadify/Loader';
import Logout from 'src/views/auth/logout/Logout';

const AppRouter = () => {
  const { isLoggedIn, isLoaded, user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { fetchEmployeeDetail } = useAuth();
  const [isFetching, setIsFetching] = useState(true);
  useEffect(() => {
    if (isLoggedIn && !isLoaded) {
      // Get employee details

      const fetchData = async () => {
        const employeeDetail = await fetchEmployeeDetail(user.id);
        if(employeeDetail?.data){
          const { employee } = employeeDetail.data;
          const employeeData = {
            isSuperAdmin: employee?.super_admin,
            avatar: employee?.avatar,
            first_name: employee?.first_name,
            last_name: employee?.last_name,
            id: employee?.id,
            email: employee?.email,
            permissions: employee?.permissions
          };
          dispatch(updateUser(employeeData));
          setIsFetching(false);
        }
      };

      fetchData();
    } else {
      setIsFetching(false);
    }
  }, [isLoggedIn, isLoaded]);

  if (isFetching) return <Loader />;

  return (
    <Router basename={BASENAME}>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/logout" element={<Logout />} />

        {/* Admin routes wrapped in AdminLayout */}
        <Route
          path="*"
          element={
            isLoggedIn ? (
              <Routes>
                <Route path="*" element={<AuthenticatedRoutes />} />
              </Routes>
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />
      </Routes>
    </Router>
  );
};

export default AppRouter;
