import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AdminLayout from 'src/layouts/AdminLayout';
import Dashboard from '../views/dashboard/DashDefault';
import Error404 from '../views/error/default/Error404';
import BasicButton from 'src/views/ui-elements/basic/BasicButton';
import BasicBadges from 'src/views/ui-elements/basic/BasicBadges';
import BasicBreadcrumb from 'src/views/ui-elements/basic/BasicBreadcrumb';
import BasicPagination from 'src/views/ui-elements/basic/BasicPagination';
import BasicCollapse from 'src/views/ui-elements/basic/BasicCollapse';
import BasicTabsPills from 'src/views/ui-elements/basic/BasicTabsPills';
import BasicTypography from 'src/views/ui-elements/basic/BasicTypography';
import FormsElements from 'src/views/forms/FormsElements';
import BootstrapTable from 'src/views/tables/BootstrapTable';
import Nvd3Chart from 'src/views/charts/nvd3-chart';
// import GoogleMaps from 'src/views/maps/GoogleMaps';
import SamplePage from 'src/views/extra/SamplePage';
import Employees from 'src/views/Employees';
import LeavePolicy from 'src/views/LeavePolicy';
import LeaveRequests from 'src/views/LeaveRequests';
import LeaveApplication from 'src/views/LeaveApplication';
import EmployeeProfile from 'src/views/Employees/EmployeeProfile';
import AddEmployee from 'src/views/Employees/AddEmployee';
import UpdateEmployee from 'src/views/Employees/UpdateEmployee';
import Payslips from 'src/views/Payslips';

const WrapLayout = ({ children }) => {
  return <AdminLayout>{children}</AdminLayout>;
};
const AuthenticatedRoutes = () => {
  return (
    <Routes>
      {/* <Route
        path="dashboard"
        element={
          <WrapLayout>
            <Dashboard />
          </WrapLayout>
        }
      /> */}
      <Route
        path="employees"
        element={
          <WrapLayout>
            <Employees />
          </WrapLayout>
        }
      />
      <Route
        path="employee/add"
        element={
          <WrapLayout>
            <AddEmployee />
          </WrapLayout>
        }
      />
      <Route
        path="employee/edit/:empID"
        element={
          <WrapLayout>
            <UpdateEmployee />
          </WrapLayout>
        }
      />
      <Route
        path="employee/:empID"
        element={
          <WrapLayout>
            <EmployeeProfile />
          </WrapLayout>
        }
      />
      <Route
        path="payslips"
        element={
          <WrapLayout>
            <Payslips />
          </WrapLayout>
        }
      />
      <Route
        path="leave-policy"
        element={
          <WrapLayout>
            <LeavePolicy />
          </WrapLayout>
        }
      />
      <Route
        path="leave-requests"
        element={
          <WrapLayout>
            <LeaveRequests />
          </WrapLayout>
        }
      />
      <Route
        path="leave-application"
        element={
          <WrapLayout>
            <LeaveApplication />
          </WrapLayout>
        }
      />
      <Route
        path="/dashboard"
        element={
          <WrapLayout>
            <Dashboard />
          </WrapLayout>
        }
      />
      <Route
        path="basic/button"
        element={
          <WrapLayout>
            <BasicButton />
          </WrapLayout>
        }
      />

      <Route
        path="/basic/badges"
        element={
          <WrapLayout>
            <BasicBadges />
          </WrapLayout>
        }
      />
      <Route
        path="/basic/breadcrumb"
        element={
          <WrapLayout>
            <BasicBreadcrumb />
          </WrapLayout>
        }
      />
      <Route
        path="/basic/pagination"
        element={
          <WrapLayout>
            <BasicPagination />
          </WrapLayout>
        }
      />
      <Route
        path="/basic/collapse"
        element={
          <WrapLayout>
            <BasicCollapse />
          </WrapLayout>
        }
      />
      <Route
        path="/basic/tabs-pills"
        element={
          <WrapLayout>
            <BasicTabsPills />
          </WrapLayout>
        }
      />
      <Route
        path="/basic/typography"
        element={
          <WrapLayout>
            <BasicTypography />
          </WrapLayout>
        }
      />
      <Route
        path="/forms/form-basic"
        element={
          <WrapLayout>
            <FormsElements />
          </WrapLayout>
        }
      />
      <Route
        path="/tables/bootstrap"
        element={
          <WrapLayout>
            <BootstrapTable />
          </WrapLayout>
        }
      />
      <Route
        path="/charts/nvd3"
        element={
          <WrapLayout>
            <Nvd3Chart />
          </WrapLayout>
        }
      />
      {/* <Route
        path="/maps/google-map"
        element={
          <WrapLayout>
            <GoogleMaps />
          </WrapLayout>
        }
      /> */}
      <Route
        path="/sample-page"
        element={
          <WrapLayout>
            <SamplePage />
          </WrapLayout>
        }
      />

      <Route path="*" element={<Error404 />} />
      {/* Add other admin routes */}
    </Routes>
  );
};

export default AuthenticatedRoutes;
