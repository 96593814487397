import useRequest from 'src/hooks/useRequest';

const useAuth = () => {
  const { request } = useRequest();
  const userLogin = async ({ email, password, organization_code }) => {
    return request({
      method: 'post',
      url: '/sessions',
      data: { email, password },
      headers: { organization: organization_code },
    });
  };
  const register = async ({ name, email, password, organization_code }) => {
    return request({
      method: 'post',
      url: '/admin/register',
      data: { name, email, password, organization_code }
    });
  };

  const fetchEmployees = async () => {
    return request({
      method: 'get',
      url: '/employees'
    });
  };


  const fetchActiveLeaves = async () => {
    return request({
      method: 'get',
      url: '/leave_transactions/fetch_employee_leaves'
    });
  };

  const fetchEmployeeDetail = async (id) => {
    if(id){
      return request({
        method: 'get',
        url: `/employees/${id}`
      });
    }
  };

  const addEmployee = async (data) => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (key === 'designation_id') {
        formData.append('employee_designations_attributes[][designation_id]', data.designation_id);
        formData.append('employee_designations_attributes[][active]', true);
      } else if (key === 'salary') {
        formData.append('employee_salaries_attributes[][salary]', data.salary);
      } else if (['street' || 'city' || 'state' || 'zipcode'].includes(key)) {
        // formData.append('address_attributes[id]', data.address_id);
        formData.append('address_attributes[street]', data.street);
        formData.append('address_attributes[city]', data.city);
        formData.append('address_attributes[state]', data.state);
        formData.append('address_attributes[zipcode]', data.zipcode);
      } else if (![null, 'null', ''].includes(data[key])) {
        formData.append(key, data[key]);
      }
    });

    return request({
      // baseURL: 'http://decabits.lvh.me:3004/',
      method: 'post',
      url: `/employees`,
      data: formData
    });
  };

  const updateEmployeeDetail = async (id, data) => {
    delete data.id;
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (key === 'employee_designations') {
        formData.append('employee_designations_attributes[][designation_id]', data.employee_designations.designation_id);
        formData.append('employee_designations_attributes[][active]', true);
      } else if (key === 'salary') {
        formData.append('employee_salaries_attributes[][salary]', data.salary);
      } else if (['address_id' || 'street' || 'city' || 'state' || 'zipcode'].includes(key)) {
        if (data.address_id) {
          formData.append('address_attributes[id]', data.address_id);
        }
        formData.append('address_attributes[street]', data.street);
        formData.append('address_attributes[city]', data.city);
        formData.append('address_attributes[state]', data.state);
        formData.append('address_attributes[zipcode]', data.zipcode);
      } else if (![null, 'null', ''].includes(data[key])) {
        formData.append(key, data[key]);
      }
    });
    // if(!data.avatar) {
    //   formData.append('avatar', null);
    // }
    return request({
      method: 'put',
      url: `/employees/${id}`,
      data: formData
    });
  };

  const fetchManagerList = async (id) => {
    return request({
      method: 'get',
      url: `/employees/${id}/fetch_managers`
    });
  };

  const assignManagers = async (id, { managers }) => {
    const newManagerList = Object.entries(managers)
      .filter(([key, value]) => value === true)
      .map(([key]) => ({ manager_id: parseInt(key) }));

    return request({
      method: 'post',
      url: `/employees/${id}/link_managers`,
      data: {
        manager_relationships_attributes: newManagerList
      }
    });
  };

  const fetchLeavePolicies = async () => {
    return request({
      method: 'get',
      url: '/leave_policies'
    });
  };

  const addLeavePolicy = async (data) => {
    const { name, approval_required, frequency, start_date, quantity, leave_type } = data;
    return request({
      method: 'post',
      url: `/leave_policies`,
      data: {
        name,
        approval_required,
        frequency,
        start_date,
        quantity,
        leave_type
      }
    });
  };

  const fetchPolicyDetail = async (id) => {
    return request({
      method: 'get',
      url: `/leave_policies/${id}`
    });
  };

  const updateLeavePolicyDetail = async (id, data) => {
    const { name, approval_required, frequency, start_date, quantity, leave_type } = data;
    return request({
      method: 'put',
      url: `/leave_policies/${id}`,
      data: {
        name,
        approval_required,
        frequency,
        start_date,
        quantity,
        leave_type
      }
    });
  };

  const deleteLeavePolicy = async (id) => {
    return request({
      method: 'delete',
      url: `/leave_policies/${id}`
    });
  };

  const fetchEmployeeList = async (id) => {
    return request({
      method: 'get',
      url: `/leave_policies/${id}/list_employees`
    });
  };

  const assignEmployees = async (id, { employees }) => {
    const newManagerList = Object.entries(employees)
      .filter(([key, value]) => value === true)
      .map(([key]) => ({ employee_id: parseInt(key) }));

    return request({
      method: 'post',
      url: `/leave_policies/${id}/assign_employees`,
      data: {
        employee_leave_policies_attributes: newManagerList
      }
    });
  };
  // Leave Transactions
  const fetchUserLeaveRequests = async () => {
    return request({
      method: 'get',
      url: '/leave_transactions'
    });
  };

  // Apply for leave
  const applyLeave = async (data) => {
    return request({
      method: 'post',
      url: `/leave_transactions`,
      data
    });
  };

  const fetchLeaveRequest = async (id) => {
    return request({
      method: 'get',
      url: `/leave_transactions/${id}`
    });
  };

  const updateLeaveRequest = async (id, data) => {
    return request({
      // baseURL: 'http://decabits.lvh.me:3004/',
      method: 'put',
      url: `/leave_transactions/${id}`,
      data
    });
  };

  const cancelLeaveRequest = async (id) => {
    return request({
      method: 'post',
      url: `/leave_transactions/${id}/cancel`
    });
  };

  const fetchLeaveForApprovals = async () => {
    return request({
      method: 'get',
      url: `/leave_transactions/fetch_manager_approvals`
    });
  };

  const approveLeaveRequest = async (id, data) => {
    return request({
      method: 'post',
      url: `/leave_transactions/${id}/approve`,
      data
    });
  };

  const rejectLeaveRequest = async (id, data) => {
    return request({
      method: 'post',
      url: `/leave_transactions/${id}/reject`,
      data
    });
  };

  const fetchActiveDesignation = async () => {
    return request({
      method: 'get',
      url: `/designation`
    });
  };

  const fetchPayslips = async () => {
    return request({
      method: 'get',
      url: '/payslip'
    });
  };

  const downloadPayslips = async (id) => {
    return request({
      method: 'get',
      url: `/payslip/${id}/download_payslip`,
      responseType: 'blob',
    });
  };

  return {userLogin, register, fetchEmployees, fetchActiveLeaves, fetchEmployeeDetail, addEmployee, updateEmployeeDetail,
    fetchManagerList, assignManagers, fetchLeavePolicies, addLeavePolicy, fetchPolicyDetail, updateLeavePolicyDetail,
    deleteLeavePolicy, fetchEmployeeList, assignEmployees, fetchUserLeaveRequests, applyLeave, fetchLeaveRequest,
    updateLeaveRequest, cancelLeaveRequest, fetchLeaveForApprovals, approveLeaveRequest, rejectLeaveRequest, fetchActiveDesignation, fetchPayslips, downloadPayslips};
}

export default useAuth;