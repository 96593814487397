import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import { Col, Form, FormCheck } from 'react-bootstrap';
import useAuth from 'src/services/api';

const schema = Yup.object().shape({});

const AssignManager = forwardRef(({ employeeID }, ref) => {
  const formikRef = useRef();
  const { assignManagers, fetchManagerList } = useAuth();
  const [managerList, setManagerList] = useState([]);
  const [checkboxOptions, setCheckboxOptions] = useState({});
  const [submitted, setSubmitted] = useState(false);
  useImperativeHandle(ref, () => ({
    resetForm() {
      formikRef.current.resetForm();
    },
    submitForm() {
      formikRef.current.submitForm();
    }
  }));

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Replace 'your_api_endpoint' with your actual API endpoint
        const { data } = await fetchManagerList(employeeID);
        console.log('manager', data?.employees);
        setManagerList(data?.employees); // Update state with fetched data
        const ckOptions = data?.employees.reduce((acc, { id, is_active }) => {
          acc[id] = is_active;
          return acc;
        }, {});
        setCheckboxOptions(ckOptions);
      } catch (error) {
        // Handle errors here
        console.error('Error fetching data:', error);
      }
    };

    // Call fetchData function when component mounts (on page load)
    fetchData();
  }, []);

  const onFormSubmit = async (values) => {
    console.log(values);
    if (values) {
      await assignManagers(employeeID, { managers: values });
    }
    setSubmitted(true);
    window.location.reload();
  };
  if (managerList && Object.keys(checkboxOptions).length > 0)
    return (
      <Formik innerRef={formikRef} validationSchema={schema} onSubmit={onFormSubmit} initialValues={checkboxOptions}>
        {({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Row>
              <Form.Group as={Col} md="12" controlId="validationFormik101">
                {managerList.length > 0 &&
                  managerList.map((manager) => (
                    <FormCheck key={manager.id}>
                      <Field id={manager.name} name={manager.id} type="checkbox" className="form-check-input" />
                      <FormCheck.Label htmlFor={manager.name} className="form-check-label">
                        {manager.name}
                      </FormCheck.Label>
                    </FormCheck>
                  ))}
                {touched.name && errors.name && (submitted || touched.name) && (
                  <Form.Control.Feedback type="invalid" tooltip>
                    {errors.name}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Form.Row>
          </Form>
        )}
      </Formik>
    );
});

export default AssignManager;
