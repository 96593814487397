import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { cloneDeep } from 'lodash';
import { Col, Form } from 'react-bootstrap';
import useAuth from 'src/services/api';
import 'react-datepicker/dist/react-datepicker.css';

const schema = Yup.object().shape({
  note: Yup.string().required('Reson for rejection is required')
});
const RejectLeaveApplication = forwardRef(({ leaveID }, ref) => {
  const formikRef = useRef();
  const { rejectLeaveRequest } = useAuth();
  const [submitted, setSubmitted] = useState(false);
  useImperativeHandle(ref, () => ({
    resetForm() {
      formikRef.current.resetForm();
    },
    submitForm() {
      formikRef.current.submitForm();
    }
  }));

  const onFormSubmit = async (values) => {
    setSubmitted(true);
    const subVal = cloneDeep(values);

    // if (subVal.note) {
    //   subVal.note = `Rejection Reason: ${subVal.note}`;
    // }
    await rejectLeaveRequest(leaveID, subVal);
    window.location.reload();
  };

  return (
    <Formik
      innerRef={formikRef}
      validationSchema={schema}
      onSubmit={onFormSubmit}
      initialValues={{
        note: ''
      }}
    >
      {({ handleSubmit, handleChange, setFieldValue, handleBlur, values, touched, errors }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Form.Row>
            <Form.Group as={Col} md="12" controlId="validationFormik104">
              <Form.Label>Reson for leave rejection:</Form.Label>

              <Form.Control
                as="textarea"
                name="note"
                rows={3}
                placeholder="Enter your reason here..."
                value={values.note}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={submitted ? !!errors.note : touched.note && !!errors.note}
              />

              {touched.note && errors.note && (submitted || touched.note) && (
                <Form.Control.Feedback type="invalid" tooltip>
                  {errors.note}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Form.Row>
        </Form>
      )}
    </Formik>
  );
});

export default RejectLeaveApplication;
