export const generateEmployeeCode = (id, prefix = 'DC') => {
  const formattedId = `00${id}`.slice(-3); // Ensure 3-digit format (e.g., 001)
  return `${prefix}${formattedId}`;
};
export const parseDate = (dateString) => {
  const parts = dateString.split('/');
  // Note: months are 0-based in JavaScript Date objects, so we subtract 1 from the month
  return new Date(parts[2], parts[1] - 1, parts[0]);
};

export const calculateAge = (dob = '') => {
  if (dob === null) return '';
  // Parse the DOB string to obtain the year, month, and day
  let dobArray = dob.split('-');
  if (dobArray.length <= 2) {
    return dob;
  }
  let dobYear = parseInt(dobArray[0]);
  let dobMonth = parseInt(dobArray[1]) - 1; // Months are zero-based
  let dobDay = parseInt(dobArray[2]);

  // Get current date
  let currentDate = new Date();
  let currentYear = currentDate.getFullYear();
  let currentMonth = currentDate.getMonth();
  let currentDay = currentDate.getDate();

  // Calculate age
  let age = currentYear - dobYear;
  // Adjust age if the current date is before the birth date for this year
  if (currentMonth < dobMonth || (currentMonth === dobMonth && currentDay < dobDay)) {
    age--;
  }
  return age;
};

export const truncateString = (str, length = 64) => {
  if (str.length > length) {
    return str.substring(0, length - 3) + '...';
  } else {
    return str;
  }
}
