import React, { useEffect, useRef, useState } from 'react';
import { Row, Col, Card, Table, Button } from 'react-bootstrap';
import FormModal from 'src/components/FormModal/FormModal';
import useAuth from 'src/services/api';
import ActionIcons from 'src/components/ActionIcons/ActionIcons';
import AssignManager from './AssignManager';
import usePermission from 'src/hooks/usePermission';
import ActionLink from 'src/components/ActionLink/ActionLink';
import { NoData } from 'src/components/NoData/NoData';
import Loader from 'src/components/Loadify/Loader';

const Employees = () => {
  const [showAssignFormModal, setShowAssignFormModal] = useState(false);
  const [selectedEmployeeID, setSelectedEmployeeID] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { isSuperAdmin, isCurrentUser } = usePermission();
  const [allEmployees, setAllEmployees] = useState([]);
  const { fetchEmployees } = useAuth();
  const updateFormikRef = useRef(null);
  const assignFormikRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Replace 'your_api_endpoint' with your actual API endpoint
        const { data } = await fetchEmployees();
        setAllEmployees(data?.employees); // Update state with fetched data
        setIsLoading(false);
      } catch (error) {
        // Handle errors here
        console.error('Error fetching data:', error);
        setIsLoading(false);
      }
    };

    // Call fetchData function when component mounts (on page load)
    fetchData();
  }, []);

  const onAssignEmployee = (id) => {
    setSelectedEmployeeID(id);
    setShowAssignFormModal(true);
  };
  const hideAssignFormModal = () => {
    setShowAssignFormModal(false);
  };

  const onAssignCancel = () => {
    assignFormikRef?.current?.resetForm();
  };

  const onAssignSubmit = () => {
    assignFormikRef?.current?.submitForm();
  };

  if (isLoading) return <Loader />;
  return (
    <React.Fragment>
      <Row>
        <Col>
          <div style={{ textAlign: 'right' }}>
            {isSuperAdmin() && (
              <ActionLink target="_blank" url={'/employee/add'}>
                <Button variant="outline-primary" style={{ marginRight: 0, marginBottom: 8 }}>
                  <i className="fas fa-plus" />
                  Add Employee
                </Button>
              </ActionLink>
            )}

            <FormModal
              show={showAssignFormModal}
              onHide={hideAssignFormModal}
              title={'Assign Manager'}
              onReset={onAssignCancel}
              onSubmit={onAssignSubmit}
              body={<AssignManager ref={assignFormikRef} employeeID={selectedEmployeeID} />}
            />
          </div>
          {allEmployees?.length === 0 ? <NoData text='No employee added yet' /> :
            <Card>
              <Card.Header>
                <Card.Title as="h5">Employees</Card.Title>
              </Card.Header>
              <Card.Body>
                <Table responsive hover>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone Number</th>
                      <th>Designation</th>
                      <th>Manager</th>
                      {/* <th>Role</th> */}
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allEmployees?.map((employee, index) => (
                      <tr key={index}>
                        <th scope="row">{employee.id}</th>
                        <td>{employee.name}</td>
                        <td>{employee.email}</td>
                        <td>{employee.phone_number}</td>
                        <td>{employee.designation}</td>
                        <td style={{ textWrap: "wrap" }}>{employee.managers.map((m) => m.name || m.email).join(', ')}</td>
                        <td style={{ display: 'flex', gap: 8 }}>
                          {isSuperAdmin() && <ActionIcons type="add" title="Assign Manager" onClick={() => onAssignEmployee(employee.id)} />}
                          <ActionIcons type="view" title="View Employee" url={`/employee/${employee.id}`} target="_blank" />
                          {(isSuperAdmin() || isCurrentUser(employee.id)) && (
                            <ActionIcons type="edit" url={`/employee/edit/${employee.id}`} title="Update Employee" target="_blank" />
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>}
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Employees;
