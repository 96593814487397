import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Formik } from 'formik';
import { cloneDeep } from 'lodash';
import DatePicker from 'react-datepicker';
import * as Yup from 'yup';
import { Col, Form } from 'react-bootstrap';
import useAuth from 'src/services/api';
import 'react-datepicker/dist/react-datepicker.css';

const schema = Yup.object().shape({
  start_date: Yup.string().required(),
  end_date: Yup.string().required(),
  duration: Yup.string().required(),
  description: Yup.string().required(),
  leave_type: Yup.string().required()
});

const UpdateLeaveRequest = forwardRef(({ leaveRequestID, submitted, setSubmitted }, ref) => {
  const formikRef = useRef();
  const { fetchLeaveRequest, updateLeaveRequest } = useAuth();
  const [leaveRequest, setLeaveRequest] = useState({});
  const [showDurationDropDown, setShowDurationDropDown] = useState(false);
  useImperativeHandle(ref, () => ({
    resetForm() {
      formikRef.current.resetForm();
    },
    submitForm() {
      console.log('triggered');
      formikRef.current.submitForm();
    }
  }));

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Replace 'your_api_endpoint' with your actual API endpoint
        const { data } = await fetchLeaveRequest(leaveRequestID);
        if (data?.leave_transaction) {
          const { start_date, end_date } = data.leave_transaction;
          data.leave_transaction.start_date = new Date(start_date);
          data.leave_transaction.end_date = new Date(end_date);
          console.log('data.leave_transaction ', data.leave_transaction);
          setLeaveRequest(data?.leave_transaction);
        }
      } catch (error) {
        // Handle errors here
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const onFormSubmit = async (values) => {
    setSubmitted(true);
    const subVal = cloneDeep(values);
    const startDate = new Date(subVal.start_date);
    const endDate = new Date(subVal.end_date);

    const formattedStartDate = `${String(startDate.getDate()).padStart(2, '0')}/${String(startDate.getMonth() + 1).padStart(
      2,
      '0'
    )}/${startDate.getFullYear()}`;
    const formattedEndDate = `${String(endDate.getDate()).padStart(2, '0')}/${String(endDate.getMonth() + 1).padStart(
      2,
      '0'
    )}/${endDate.getFullYear()}`;

    subVal.start_date = formattedStartDate;
    subVal.end_date = formattedEndDate;
    subVal.note = values.description;
    // subVal.note_attributes = { description: values.description };
    delete subVal.description;

    console.log(subVal);
    await updateLeaveRequest(leaveRequestID, subVal);
    setSubmitted(false);
    window.location.reload();
  };

  const compareDates = (date1, date2) => {
    const formattesDateA = `${String(date1.getDate()).padStart(2, '0')}-${String(date1.getMonth() + 1).padStart(
      2,
      '0'
    )}-${date1.getFullYear()}`;
    const formattesDateB = `${String(date2.getDate()).padStart(2, '0')}-${String(date2.getMonth() + 1).padStart(
      2,
      '0'
    )}-${date2.getFullYear()}`;
    if (formattesDateA === formattesDateB) {
      setShowDurationDropDown(true);
    } else {
      setShowDurationDropDown(false);
    }
  };

  if (Object.keys(leaveRequest).length > 0)
    return (
      <Formik
        innerRef={formikRef}
        validationSchema={schema}
        onSubmit={onFormSubmit}
        initialValues={{
          description: leaveRequest?.note?.description,
          end_date: leaveRequest.end_date,
          start_date: leaveRequest.start_date,
          duration: leaveRequest.duration,
          leave_type: leaveRequest.leave_type
        }}
      >
        {({ handleSubmit, handleChange, setFieldValue, handleBlur, values, touched, errors }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Row>
              <Form.Group as={Col} md="12" controlId="validationFormik101">
                <Form.Label>Start Date *</Form.Label>
                <br />
                <DatePicker
                  name="start_date"
                  selected={values.start_date}
                  onChange={(date) => {
                    if (values.end_date) {
                      compareDates(date, values.end_date);
                    }
                    setFieldValue('start_date', date);
                  }}
                  autoComplete="off"
                  shouldCloseOnSelect={true}
                  closeOnSelect={true}
                  className={`form-control ${touched.start_date && errors.start_date ? 'is-invalid' : ''}`}
                />

                {touched.start_date && errors.start_date && (submitted || touched.start_date) && (
                  <Form.Control.Feedback type="invalid" tooltip>
                    {errors.start_date}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group as={Col} md="12" controlId="validationFormik103">
                <Form.Label>End Date *</Form.Label>
                <br />
                <DatePicker
                  name="end_date"
                  selected={values.end_date}
                  onChange={(date) => {
                    if (values.start_date) {
                      compareDates(date, values.start_date);
                    }
                    setFieldValue('end_date', date);
                  }}
                  autoComplete="off"
                  shouldCloseOnSelect={true}
                  closeOnSelect={true}
                  className={`form-control ${touched.end_date && errors.end_date ? 'is-invalid' : ''}`}
                />

                {touched.end_date && errors.end_date && (submitted || touched.end_date) && (
                  <Form.Control.Feedback type="invalid" tooltip>
                    {errors.end_date}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              {showDurationDropDown && (
                <Form.Group as={Col} md="12" controlId="validationFormik105">
                  <Form.Label>Duration *</Form.Label>
                  <Form.Control
                    as="select"
                    name="duration"
                    value={values.duration}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={submitted ? !!errors.duration : touched.duration && !!errors.duration}
                  >
                    <option value="half">Half</option>
                    <option value="full">Full</option>
                  </Form.Control>

                  {touched.duration && errors.duration && (submitted || touched.duration) && (
                    <Form.Control.Feedback type="invalid" tooltip>
                      {errors.duration}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              )}
              <Form.Group as={Col} md="12" controlId="validationFormik105">
                <Form.Label>Leave Type *</Form.Label>
                <Form.Control
                  as="select"
                  name="leave_type"
                  value={values.leave_type}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={submitted ? !!errors.leave_type : touched.leave_type && !!errors.leave_type}
                >
                  <option value="casual">Casual</option>
                  <option value="sick">Sick</option>
                  {/* <option value="wfh">Work From Home</option>
                  <option value="misc">Miscellaneous</option> */}
                </Form.Control>

                {touched.leave_type && errors.leave_type && (submitted || touched.leave_type) && (
                  <Form.Control.Feedback type="invalid" tooltip>
                    {errors.leave_type}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group as={Col} md="12" controlId="validationFormik104">
                <Form.Label>Reason *</Form.Label>
                <Form.Control
                  type="text"
                  name="description"
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={submitted ? !!errors.description : touched.description && !!errors.description}
                />

                {touched.description && errors.description && (submitted || touched.description) && (
                  <Form.Control.Feedback type="invalid" tooltip>
                    {errors.description}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Form.Row>
          </Form>
        )}
      </Formik>
    );
});

export default UpdateLeaveRequest;
