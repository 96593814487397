import Axios from 'axios';
import { useDispatch } from 'react-redux';
import { CONFIG } from 'src/config/constant';
import { useToasts } from 'react-toast-notifications';
import { logout } from 'src/store/slices/authSlice';

const useRequest = () => {
    const { addToast } = useToasts();
    const dispatch = useDispatch();

    const request = async ({ baseURL = CONFIG.API_URL, method = 'get', url, data, params, extra, headers = {}, responseType = 'json' }) => {
        try {
            Axios.defaults.baseURL = baseURL;
            const config = { url: `/api${url}`, method, ...extra, responseType };
            config.headers = headers;
            const token = localStorage.getItem('token');
            const organization_code = localStorage.getItem('organization_code');
            if (token) config.headers = { ...config.headers, Authorization: `Bearer ${token}` };
            if (organization_code) config.headers = { ...config.headers, organization: organization_code };
            if (!['GET', 'get'].includes(method) && data) config.data = data;
            if (params) config.params = params;
            const resp = await Axios({ ...config });
            return { data: resp?.data, headers: resp?.headers };
        } catch (e) {
            if (e?.response?.status === 401) {
                addToast(`Access denied!`, {
                    appearance: 'error',
                    autoDismiss: true
                });
                dispatch(logout());
            }
            return { data: e?.response?.data };
        }
    };

    return { request };
};

export default useRequest;
