import React, { useEffect, useRef, useState } from 'react';
import { Row, Col, Card, Table, Button } from 'react-bootstrap';
import FormModal from 'src/components/FormModal/FormModal';
import useAuth from 'src/services/api';
import ActionIcons from 'src/components/ActionIcons/ActionIcons';
import AddLeavePolicy from './AddLeavePolicy';
import ActionModal from 'src/components/ActionModal/ActionModal';
import UpdateLeavePolicy from './UpdatePolicy';
import AssignLeavePolicy from './AssignLeavePolicy';
import usePermission from 'src/hooks/usePermission';
import { NoData } from 'src/components/NoData/NoData';
import Loader from 'src/components/Loadify/Loader';

const MODULE_NAME = 'leave_policy';

const LeavePolicy = () => {
  const { deleteLeavePolicy, fetchLeavePolicies } = useAuth();
  const { isSuperAdmin, isModuleAccessible } = usePermission();
  const [showFormModal, setSetShowFormModal] = useState(false);
  const [showUpdateFormModal, setShowUpdateFormModal] = useState(false);
  const [showAssignFormModal, setShowAssignFormModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedPolicyID, setSelectedPolicyID] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [allLeaves, setAllLeaves] = useState([]);
  const formikRef = useRef(null);
  const updateFormikRef = useRef(null);
  const assignFormikRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Replace 'your_api_endpoint' with your actual API endpoint
        const { data } = await fetchLeavePolicies();
        console.log('datadatadatadata', data);

        setAllLeaves(data?.leave_policies); // Update state with fetched data
        setIsLoading(false);
      } catch (error) {
        // Handle errors here
        console.error('Error fetching data:', error);
        setIsLoading(false);
      }
    };

    // Call fetchData function when component mounts (on page load)
    fetchData();
  }, []);

  const onAddPolicy = () => {
    setSetShowFormModal(true);
  };
  const hideFormModal = () => {
    setSetShowFormModal(false);
  };
  const onUpdatePloicy = (id) => {
    setSelectedPolicyID(id);
    setShowUpdateFormModal(true);
  };
  const hideUpdateFormModal = () => {
    setShowUpdateFormModal(false);
  };
  const hideDeleteFormModal = () => {
    setShowDeleteModal(false);
  };

  const onReset = () => {
    formikRef?.current?.resetForm();
  };

  const onSubmit = () => {
    formikRef?.current?.submitForm();
  };

  const onUpdateCancel = () => {
    updateFormikRef?.current?.resetForm();
  };

  const onUpdateSubmit = () => {
    console.log('submitForm');
    updateFormikRef?.current?.submitForm();
  };

  const onAssignEmployee = (id) => {
    setSelectedPolicyID(id);
    setShowAssignFormModal(true);
  };
  const hideAssignFormModal = () => {
    setShowAssignFormModal(false);
  };

  const onAssignCancel = () => {
    assignFormikRef?.current?.resetForm();
  };

  const onAssignSubmit = () => {
    assignFormikRef?.current?.submitForm();
  };

  const onDeletePolicy = (id) => {
    setSelectedPolicyID(id);
    setShowDeleteModal(true);
  };

  const onDeleteClick = async () => {
    // Delete selected ploicy
    await deleteLeavePolicy(selectedPolicyID);
    window.location.reload();
  };

  if (isLoading) return <Loader />;
  if (allLeaves)
    return (
      <React.Fragment>
        <Row>
          <Col>
            <div style={{ textAlign: 'right' }}>
              {isModuleAccessible(MODULE_NAME, 'manage') && <Button variant="outline-primary" style={{ marginRight: 0 }} onClick={onAddPolicy}>
                <i className="fas fa-plus" />
                Add Leave Policy
              </Button>}
              <FormModal
                show={showFormModal}
                onHide={hideFormModal}
                title={'Add Leave Policy'}
                onReset={onReset}
                onSubmit={onSubmit}
                body={<AddLeavePolicy ref={formikRef} />}
              />
              <FormModal
                show={showUpdateFormModal}
                onHide={hideUpdateFormModal}
                title={'Update Policy'}
                onReset={onUpdateCancel}
                onSubmit={onUpdateSubmit}
                body={<UpdateLeavePolicy ref={updateFormikRef} policyID={selectedPolicyID} />}
              />
              <FormModal
                show={showAssignFormModal}
                onHide={hideAssignFormModal}
                title={'Assign Leave Policy'}
                onReset={onAssignCancel}
                onSubmit={onAssignSubmit}
                body={<AssignLeavePolicy ref={assignFormikRef} policyID={selectedPolicyID} />}
              />
              <ActionModal
                show={showDeleteModal}
                onHide={hideDeleteFormModal}
                title={'Delete Policy'}
                onCancel={hideDeleteFormModal}
                onSubmit={onDeleteClick}
                body={'Are you sure you want to delete this policy?'}
              />
            </div>
            {allLeaves.length === 0 ? <NoData text='No policy added yet' /> :
              <Card>
                <Card.Header>
                  <Card.Title as="h5">Leave Policy</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Table responsive hover>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Approval Required</th>
                        <th>Frequency</th>
                        <th>Quantity</th>
                        <th>Start Date</th>
                        <th>Leave Type</th>
                        {(isSuperAdmin() || isModuleAccessible(MODULE_NAME, 'manage')) && <th>Action</th>}
                      </tr>
                    </thead>
                    <tbody>
                      {allLeaves.map((leave, index) => {
                        const date = new Date(leave.start_date);
                        const formattedDate = `${String(date.getUTCDate()).padStart(2, '0')}/${String(date.getUTCMonth() + 1).padStart(
                          2,
                          '0'
                        )}/${date.getUTCFullYear()}`;
                        return (
                          <tr key={index}>
                            <td>{leave.name}</td>
                            <td>{leave.approval_required ? 'Yes' : 'No'}</td>
                            <td>{leave.frequency}</td>
                            <td>{leave.quantity}</td>
                            <td>{formattedDate}</td>
                            <td>{leave.leave_type}</td>
                            {(isSuperAdmin() || isModuleAccessible(MODULE_NAME, 'manage')) && <td style={{ display: 'flex', gap: 8 }}>
                              <ActionIcons type="add" title="Assign Manager" onClick={() => onAssignEmployee(leave.id)} />
                              <ActionIcons type="edit" title="Update Policy" onClick={() => onUpdatePloicy(leave.id)} />
                              <ActionIcons type="delete" title="Delete Policy" onClick={() => onDeletePolicy(leave.id)} />
                            </td>}
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>}
          </Col>
        </Row>
      </React.Fragment>
    );
};

export default LeavePolicy;
