import React from 'react';

const Loader = () => {
  return (
    <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
      <div className="text-center">
        <div className="spinner-border text-primary mb-3" role="status">
          <span className="sr-only">Loading...</span>
        </div>
        <h4
          className="mb-0"
          style={{ fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif", fontWeight: 600, fontSize: '14px', color: '#333' }}
        >
          LOADING...
        </h4>
      </div>
    </div>
  );
};

export default Loader;
