import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: JSON.parse(localStorage.getItem('user')),
    isLoggedIn: localStorage.getItem('isLoggedIn') === 'true',
    isLoaded: !!JSON.parse(localStorage.getItem('user'))?.email
  },
  reducers: {
    updateUser: (state, action) => {
      const user = { ...action.payload, token: state?.user?.token };
      state.isLoggedIn = true;
      state.user = user;
      localStorage.setItem('isLoggedIn', true);
      localStorage.setItem('user', JSON.stringify({ id: user?.id }));
    },
    login: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload;
      localStorage.setItem('isLoggedIn', true);
      localStorage.setItem('user', JSON.stringify({ id: action.payload?.id }));
    },
    logout: (state) => {
      localStorage.removeItem('isLoggedIn');
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      localStorage.removeItem('organization_code');
      state.isLoggedIn = false;
      state.user = null;
    }
  }
});

// Action creators are generated for each case reducer function
export const { login, logout, updateUser } = authSlice.actions;

export default authSlice.reducer;
