import React, { useEffect, useRef, useState } from 'react';
import { Row, Col, Card, Table } from 'react-bootstrap';
import useAuth from 'src/services/api';
import ActionIcons from 'src/components/ActionIcons/ActionIcons';
import { capitalize } from 'lodash';
import { LEAVE_REQUEST_TYPE } from 'src/config/constant';
import usePermission from 'src/hooks/usePermission';
import FormModal from 'src/components/FormModal/FormModal';
import ApproveLeaveApplication from './ApproveLeaveApplication';
import RejectLeaveApplication from './RejectLeaveApplication';
import InfoPopup from 'src/components/InfoPopup/InfoPopup';
import { truncateString } from 'src/utils/common';
import { NoData } from 'src/components/NoData/NoData';
import Loader from 'src/components/Loadify/Loader';

const MODULE_NAME = 'leave_application';

const LeaveApplication = () => {
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const { fetchLeaveForApprovals } = useAuth();
  const [selectedLeaveID, setSelectedLeaveID] = useState(null);
  const { isSuperAdmin, isModuleAccessible } = usePermission();
  const [isLoading, setIsLoading] = useState(true);
  const [allLeaves, setAllLeaves] = useState([]);
  const approveFormikRef = useRef(null);
  const rejectFormikRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await fetchLeaveForApprovals();
        console.log('datadatadatadata', data);

        setAllLeaves(data?.leave_transactions);
        setIsLoading(false);
      } catch (error) {
        // Handle errors here
        console.error('Error fetching data:', error);
        setIsLoading(false);
      }
    };

    // Call fetchData function when component mounts (on page load)
    fetchData();
  }, []);

  const hideApproveFormModal = () => {
    setShowApproveModal(false);
  };

  const onApproveLeave = (id) => {
    setSelectedLeaveID(id);
    setShowApproveModal(true);
  };

  const onApproveClick = async () => {
    // Delete selected ploicy
    // await approveLeaveRequest(selectedLeaveID);
    // window.location.reload();
    approveFormikRef?.current?.submitForm();
  };

  const hideCancelFormModal = () => {
    setShowCancelModal(false);
  };

  const onCancelLeave = (id) => {
    setSelectedLeaveID(id);
    setShowCancelModal(true);
  };

  const onCancelClick = async () => {
    // Delete selected ploicy
    // await rejectLeaveRequest(selectedLeaveID);
    // window.location.reload();
    rejectFormikRef?.current?.submitForm();
  };

  if (isLoading) return <Loader />;
  if (allLeaves)
    return (
      <React.Fragment>
        <Row>
          <Col>
            <div style={{ textAlign: 'right' }}>
              {/* <ActionModal
                show={showApproveModal}
                onHide={hideApproveFormModal}
                title={'Approve Leave'}
                onCancel={hideApproveFormModal}
                onSubmit={onApproveClick}
                body={'Do you sure you want to approve this leave request?'}
              /> */}
              <FormModal
                show={showApproveModal}
                onHide={hideApproveFormModal}
                title={'Approve Leave'}
                onReset={hideApproveFormModal}
                onSubmit={onApproveClick}
                body={<ApproveLeaveApplication ref={approveFormikRef} leaveID={selectedLeaveID} />}
              />
              <FormModal
                show={showCancelModal}
                onHide={hideCancelFormModal}
                title={'Approve Leave'}
                onReset={hideCancelFormModal}
                onSubmit={onCancelClick}
                body={<RejectLeaveApplication ref={rejectFormikRef} leaveID={selectedLeaveID} />}
              />
            </div>
            {allLeaves.length === 0 ? <NoData text='No Applications submitted yet' /> :
              <Card>
                <Card.Header>
                  <Card.Title as="h5">Leave Application</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Table responsive hover>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Leave Type</th>
                        <th>Duration</th>
                        <th>Status</th>
                        <th>Approved/Rejected By</th>
                        <th>Leave Reason</th>
                        {(isSuperAdmin() || isModuleAccessible(MODULE_NAME, 'manage')) && <th>Action</th>}
                      </tr>
                    </thead>
                    <tbody>
                      {allLeaves.map((leave, index) => {
                        const startDate = new Date(leave.start_date);
                        const endDate = new Date(leave.end_date);
                        const formattedStartDate = `${String(startDate.getUTCDate()).padStart(2, '0')}/${String(
                          startDate.getUTCMonth() + 1
                        ).padStart(2, '0')}/${startDate.getUTCFullYear()}`;
                        const formattedEndDate = `${String(endDate.getUTCDate()).padStart(2, '0')}/${String(
                          endDate.getUTCMonth() + 1
                        ).padStart(2, '0')}/${endDate.getUTCFullYear()}`;
                        return (
                          <tr key={index}>
                            <td>{leave?.employee?.name}</td>
                            <td>{formattedStartDate}</td>
                            <td>{formattedEndDate}</td>
                            <td>{capitalize(leave.leave_type)}</td>
                            <td>{capitalize(leave.duration)}</td>
                            <td>
                              <span>{LEAVE_REQUEST_TYPE[leave.status]}</span>
                              {['approved', 'rejected'].includes(leave.status) &&
                                (<InfoPopup type='circleInfo' align='right' text={leave.status === 'approved' ? leave.approved_by?.note?.description
                                  : leave.rejected_by?.note?.description} />)}
                            </td>
                            <td>
                              {leave.status === 'rejected' && (
                                <span>{leave.rejected_by.name}</span>
                              )}
                              {leave.status === 'approved' && (
                                <span>{leave.approved_by.name}</span>
                              )}
                            </td>
                            <td>
                              {leave.note?.description?.length > 15 ? <>{truncateString(leave.note?.description, 15)}
                                <InfoPopup type='circleInfo' text={leave.note?.description} />
                              </> : leave.note?.description}
                            </td>
                            {
                              (isSuperAdmin() || isModuleAccessible(MODULE_NAME, 'manage')) &&
                              (!['approved', 'rejected'].includes(leave.status) ? <td style={{ display: 'flex', gap: 8 }}>
                                <ActionIcons type="tick" title="Approve Leave" onClick={() => onApproveLeave(leave.id)} />
                                <ActionIcons type="cancel" title="Reject leave" onClick={() => onCancelLeave(leave.id)} />
                              </td> : <td style={{ textAlign: "center" }}>--</td>)
                            }
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>}
          </Col>
        </Row>
      </React.Fragment>
    );
};

export default LeaveApplication;
